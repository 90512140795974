import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import YouTube from 'react-youtube';
import printform from "./Print.js"
import $ from "jquery";

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const systemid = params.get("systemid");
    const id = params.get("id");

    const history = useHistory();

    const [videoData, setvideoData] = useState({
        videoLong: '',
        videodate:'',
    });

    const [uploaded, setUploaded] = useState([]);

    const [func, setFunc] = useState([]);

    const [warn, setWarn] = useState([]);

    const [formData, setFormData] = useState([]);

    const [key, setkey] = useState([]);

    const [WebImg, setWebImg] = useState([]);


    const funcData = useCallback(async (systemid) => {
        const response = await fetch('web/doc/getFunc/' + systemid);
        const data = await response.json();
        setFunc(data);
    }, []);

    const warnData = useCallback(async () => {
        const response = await fetch('web/doc/getWarn');
        const data = await response.json();
        setWarn(data);
    }, []);

    const Att = useCallback(async (systemid) => {
        const response = await fetch('web/doc/getCourseAtt/' + systemid);
        const data = await response.json();
        setUploaded(data);
    }, []);


    const Data = useCallback(async (id) => {
        fetch('web/doc/getCourseDetail/' + id)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setFormData(data.doc);
                } else {
                    alert(data.message);
                    history.goBack();
                }
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
        
    }, [history]);

    const ytkey = useCallback(async () => {
        fetch('web/setting/GetYoutube' )
            .then((response) => response.text())
            .then((data) => {
                setkey(data);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }, []);




    const ytData = useCallback(async() => {
        const url = 'https://www.googleapis.com/youtube/v3/videos?id=' + formData.docVideoId + '&key=' + key + '&part=snippet,contentDetails,statistics,status';
        const response = await fetch(url);
        const data = await response.json();

        const date = changeDate(data.items[0].snippet.publishedAt);
        const time = changeTime(data.items[0].contentDetails.duration);
        setvideoData({ "videoLong": time, "videodate": date });
    }, [formData.docVideoId, key]);


    useEffect(() => {
        funcData(systemid);
        warnData();
        Data(id);
        Att(id);
        ytkey();
        imgurl();
    }, [Att, Data, funcData, id, systemid, warnData, ytkey]);

    useEffect(() => {
        if (formData.docVideoId != null && key !=="") { 
            ytData();
        }
    }, [formData.docVideoId, key, ytData]);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);


    const handlecancel = (event) => {
        event.preventDefault();
        //history.push('/intranet/video/CourseList?systemid=' + systemid + '&page=1');
        history.goBack();
    }

    function handleFileDownload(event, id, name) {
        event.preventDefault();
        fetch('/web/doc/DownloadAtt/' + systemid + '/' + id + '/' + formData.docId,)
            .then((response) => response.blob())
            .then((blob) => {      
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = name;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error(error);
            });

    }

    async function imgurl() {
        const response = await fetch('web/setting/GetWebImg');
        const data = await response.text();
        setWebImg(data);
    }

    return (
        <>
            <div className="page-main" id="Print_area">
                <div className="page-kv page-kv-course inner">
                    <h2 className="page-kv-title"><strong>{func.funcName}</strong></h2>
                </div>

                <div className="crumb">
                    <div className="inner crumb-inner">
                        <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >
                        <a  onClick={handlecancel} title={ func.funcName }  >{func.funcName}</a> >
                        <strong className="current">{formData.docTitle}</strong>
                    </div>
                </div>

                <section className="page">
                    <div className="inner">
                        <div className="page-header">
                            <div className="page-title">{formData.docTitle }</div>
                            <div className="page-share">
                                <a title="分享" />
                                <ul className="page-share-list">
                                    <li>
                                        <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                            <img src="assets/images/fb_icon.png" alt="fb icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                            <img src="assets/images/line_icon.png" alt="line icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                            <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                        </a>
                                    </li>
                                    <li>
                                        {printform()}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="className-video-cont">
                            <VideoPlayer yt={formData.docVideoId} />
                            <ul className="video-info">
                                <li>發佈時間：{formData.docSDate}</li>
                                <li>影片長度：{videoData.videoLong}</li>
                                <li ><span>相關檔案：</span>
                                {
                                        uploaded.map((upload, index) =>
                                            <span key={index }>
                                            <a onClick={(event) => handleFileDownload(event, upload.docAttId, upload.docAttContentReal )} className="file">
                                            <div className="fileFormat">
                                            <img src="assets/images/file-pdf-i.svg" alt="" />
                                            </div>
                                            <span>{upload.docAttContentReal }</span>
                                            </a>
                                            </span>
                                    )
                                }
                                </li>
                            </ul>
                        </div>
                        <div className="page-btn bottom ">
                            <a  onClick={handlecancel } className="back " title="回上頁 ">回上頁</a>
                        </div>
                    </div>
                </section>
                <div id="popup_warning">
                    {formData.docWarn === 1 &&
                        <div className="popup warning">
                            <div className="popup-box">
                                <a className="close-btn" onClick={(event) => closewarn(event)}><img src="assets/images/i-close-white.svg" alt="" /></a>
                                <div className="cont">
                                    <div className="warn-pic">
                                        <img src={WebImg+"Warn/" + warn.tfFile} alt={warn.tfFilename} />
                                    </div>
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: warn.tfContent }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )

    function VideoPlayer(prop) {
         const videoId = prop.yt;
         const opts = {
             height: '390',
             width: '640',
         };
         return (
             <div className="video" >
                 <YouTube videoId={videoId} opts={opts} />
             </div>
         );
    }

    function closewarn(event) {
        event.preventDefault();
        $("#popup_warning").addClass("hide");
    }

    function changeDate(date) {

        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();
        const formattedDate = `${year}/${month}/${day}`;
        return formattedDate;

    }

    function changeTime(time) {

        const durationRegex = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
        const durationMatch = durationRegex.exec(time);
        const hours = parseInt(durationMatch[1] || '0');
        const minutes = parseInt(durationMatch[2] || '0');
        const seconds = parseInt(durationMatch[3] || '0');

        const duration = new Date();
        duration.setHours(hours, minutes, seconds);

        const hoursString = duration.getHours().toString().padStart(2, '0');
        const minutesString = duration.getMinutes().toString().padStart(2, '0');
        const secondsString = duration.getSeconds().toString().padStart(2, '0');

        const formattedDuration = `${hoursString}:${minutesString}:${secondsString}`;

        return formattedDuration ;
        
    }


} export default From;

//<ReactPlayer url="https://www.youtube.com/watch?v=dQw4w9WgXcQ"  />