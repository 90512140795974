
import React, { useState, useEffect, } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import Banner from './Banner';


function Index() {

    const location = useLocation();
    const history = useHistory();

    const [Trader, setTrader] = useState("");
    const [Practitioners, setPractitioners] = useState("");
    const [AdvocateVideo, setAdvocateVideo] = useState("");
    const [EventNews, setEventNews] = useState("");
    const [OtherCourses, setOtherCourses] = useState("");
    const [TaifexLearn, setTaifexLearn] = useState("");

    async function data() {
        const response = await fetch('web/index/getIndexUrl');
        const data = await response.json();

        setTrader(data.find(item => item.funcCode === "Trader").funcInterUrl);
        setPractitioners(data.find(item => item.funcCode === "Practitioners").funcInterUrl);
        setAdvocateVideo(data.find(item => item.funcCode === "AdvocateVideo").funcInterUrl);
        setEventNews(data.find(item => item.funcCode === "EventNews").funcInterUrl);
        setOtherCourses(data.find(item => item.funcCode === "OtherCourses").funcInterUrl);
        setTaifexLearn(data.find(item => item.funcCode === "TaifexLearn").funcInterUrl);
    }

    useEffect(() => {
        doCount();
        data();
    }, []);



    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    return (
        <>
            <Banner />
            <div className="index-main">
                <section className="index-content-1">
                    <div className="indexInner">
                        <div className="left">
                            <div className="cont">
                                <img src="assets/images/left-pic.png" alt="" />
                                <div className="info">
                                    <strong>期貨交易實務課程</strong>
                                    <p>不受時地空間限制，線上參加期貨及選擇權的各式課程，了解期交所新商品及新制度的最新資訊，提供一般交易人及從業人員學習期貨及選擇權的最佳選擇！</p>
                                    <div className="btn-wrap">
                                        <Link to={Trader} className="more-btn">探索課程</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="cont">
                                {/*<img src="assets/images/right-pic.jpg" alt="" />*/}
                                <div className="info">
                                    <strong>期貨及選擇權數位學習網</strong>
                                    <p>現在就跟著期貨及選擇權數位學習網一起探索期貨及選擇權的世界！</p>
                                    <div className="btn-wrap">
                                        <a href={TaifexLearn} onClick={doLearnCount} className="more-btn" target="_blank" rel="noreferrer">探索課程</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="index-content-2">
                    <div className="indexInner">
                        <div className="left">
                            <ul className="tour-list">
                                <li>
                                    <Link to={AdvocateVideo}>
                                        <div className="icon">
                                            <img src="assets/images/tour-1.png" alt="" />
                                        </div>
                                        <div className="info">
                                            <strong>宣導影片</strong>
                                            <p>介紹期貨商品及制度</p>
                                            <p></p>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={EventNews}>
                                        <div className="icon">
                                            <img src="assets/images/tour-2.png" alt="" />
                                        </div>
                                        <div className="info">
                                            <strong>活動與新聞</strong>
                                            <p>觀看期交所的公開活動及報導</p>
                                            <p></p>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={OtherCourses}>
                                        <div className="icon">
                                            <img src="assets/images/tour-3.png" alt="" />
                                        </div>
                                        <div className="info">
                                            <strong>其他課程</strong>
                                            <p>了解其他相關課程或資訊</p>
                                            <p></p>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="right">
                            <ul className="link-list">
                                <li>
                                    <Link to="/CompanyProfile">
                                        <div className="icon">
                                            <img src="assets/images/link-icon-1.png" alt="" />
                                        </div>
                                        <p>公司簡介</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Post">
                                        <div className="icon">
                                            <img src="assets/images/link-icon-2.png" alt="" />
                                        </div>
                                        <p>最新公告</p>
                                    </Link>
                                </li>
                                <li>
                                    <a href="https://mis.taifex.com.tw/futures/" target="_blank" rel="noreferrer" >
                                        <div className="icon">
                                            <img src="assets/images/link-icon-3.png" alt="" />
                                        </div>
                                        <p>即時行情</p>
                                    </a>
                                </li>
                                <li>
                                    <Link to="/News">
                                        <div className="icon">
                                            <img src="assets/images/link-icon-4.png" alt="" />
                                        </div>
                                        <p>新聞稿</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
    async function doCount() {
        const response = await fetch('web/index/doIndexCount');
        const data = await response.json();
    }
    async function doLearnCount() {
        const response = await fetch('web/index/doLearnCount');
        const data = await response.json();
    }

} export default Index;
