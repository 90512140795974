import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import printform from "./Print.js"
import $ from "jquery";
import ReactGA from "react-ga4";

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [year, setYear] = useState([]);

    const [searchData, setsearchData] = useState(0);

    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料

    const handleSearch = useCallback(async () => {
        let url = "web/news/getNewsSearch/" + searchData;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setList(data);
                setCurrentPage(0);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }, [searchData]);



    useEffect(() => {
        Data();
    }, []);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    //GA
    const path = location.pathname + location.search;
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview", page: path, page_title: "新聞稿"
        });
    }, [path]);

    async function Data() {
        const response = await fetch('/web/news/getNews');
        const data = await response.json();
        setList(data.data);
        setYear(data.date);
    }

    const ScrollToTop = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    };

    const handleInputChange = (event) => {
        setsearchData(event.target.value)
    };



    return (
        <div className="page-main" id="Print_area">
            <ScrollToTop />
            <div className="page-kv page-kv-course inner">
                <h2 className="page-kv-title"><strong>新聞稿</strong></h2>
            </div>

            <div className="crumb">
                <div className="inner crumb-inner">
                    <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >
                    <strong className="current">新聞稿</strong>
                </div>
            </div>

            <section className="page">
                <div className="search-list">
                    <div className="search-info inner">
                        <div className="date-wrap-1">
                            <label>年度</label>
                            <select name='searchYear' value={searchData} onChange={handleInputChange}>
                                <option value="0">全部</option>
                                {year.map((data) =>
                                    <option key={data} value={data}>{data}</option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="inner">
                    <div className="page-header">
                        <div className="page-title">新聞稿</div>
                        <div className="page-share"><a title="分享" />
                            <ul className="page-share-list">
                                <li>
                                    <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                        <img src="assets/images/fb_icon.png" alt="fb icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                        <img src="assets/images/line_icon.png" alt="line icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                        <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                    </a>
                                </li>
                                <li>
                                    {printform()}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <span>結果共<font color='#FF0000'>{list.length}</font>筆</span>
                    {currentData.length > 0 ?
                        <>
                            {currentData.map((data, index) =>
                                <div className='news-box' key={index}>
                                    <div className='info'>
                                        <small>{data.newsSDate.split('T')[0]}</small>
                                    </div>
                                    <a href={data.newsLink} className='title' target="_blank" rel="noreferrer" >{data.newsTitle}</a>
                                </div>
                            )}
                            <Pagination count={list.length} />
                        </>
                        :
                        <div className="paging">
                            <span>暫無資料</span>
                        </div>
                    }
                </div>
            </section>
        </div>
    )



    function Pagination(props) {

        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        return (
            <div className="paging">
                <a onClick={() =>  setCurrentPage(0)} className="first"><i className="fas fa-angle-double-left"></i>最前頁</a>
                <a onClick={() =>  currentPage > 0 && setCurrentPage(currentPage - 1)} className="prev"><i className="fas fa-angle-left"></i>上一頁</a>
                <div className="pcpaging">
                    {[...Array(num)].map((e, i) => {
                        if (num < 5 || (i >= leftRange && i <= rightRange)) {
                            return (
                                <a key={i + 1} onClick={() =>  setCurrentPage(i)} className={currentPage === i ? "current" : ""}>{i + 1}</a>

                            );
                        } else if (i === leftRange - 1 || i === rightRange + 1) {
                            return <span key={i + 1}>...</span>;
                        }
                        return null;
                    })}
                </div>
                <div className="rwdpaging">
                    <select name="" id="">
                        {[...Array(num)].map((e, i) => {
                            return (
                                <option key={i + 1} onSelect={() => setCurrentPage(i)} value="">{i + 1}</option>
                            );
                        })}
                    </select>
                </div>
                <a onClick={() => currentPage < num - 1 && setCurrentPage(currentPage + 1)} className="next">下一頁<i className="fas fa-angle-right"></i></a>
                <a onClick={() => setCurrentPage(num - 1)} className="last">最末頁<i className="fas fa-angle-double-right"></i></a>
            </div>
        );
    }


}
export default From;
