import React, { Component } from 'react';

export class Footer extends Component {
    render() {
        return (
            <footer id="footer">
                <div className="inner">
                    <div className="copyright">建議使用：Edge、Firefox、Chrome 瀏覽器</div>
                </div>
            </footer>
        )
    }
}