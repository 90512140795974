/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [func, setFunc] = useState([]);
    

    const [searchData, setsearchData] = useState({
        searchText: '',
        searchSDate: '',
        searchEDate: '',
        searchCategory: 99,
    });

    const [searchTheme, setsearchTheme] = useState("doc");

    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料

    const funcData = useCallback(async () => {
        const response = await fetch('admin/count/getFuncSelect');
        const data = await response.json();
        setFunc(data);
        
    }, []);

    useEffect(() => {
        Data();
        funcData();
    }, [funcData]);

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })

    };

    const searchThemeChange = (event) => {
        
        setsearchTheme(event.target.value)

        let url = "admin/count/getFileCount/" + event.target.value ;
        // Do something with the form data
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setList(data);
                setCurrentPage(0);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });

    };
    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    async function Data() {

        const response = await fetch('admin/count/getVideoCount');
        const data = await response.json();
        setList(data);

    }


    function handleSearch(event) {
        event.preventDefault();
        let url = "admin/count/getVideoSearch/" + systemid + "/" + searchTheme;
        let body = {
            searchText: searchData.searchText,
            searchCategory: searchData.searchCategory
        };

        if (searchData.searchSDate) {
            body.searchSDate = searchData.searchSDate;
        }

        if (searchData.searchEDate) {
            body.searchEDate = searchData.searchEDate;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                setList(data);
                setCurrentPage(0);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }


    function handleExcel(event) {

        const now = new Date();
        const year = now.getFullYear().toString();
        const month = String(now.getMonth() + 1).padStart(2, '0').toString();
        const day = String(now.getDate()).padStart(2, '0').toString();
        const today = year + month + day;

        event.preventDefault();
        let url = "admin/count/VideoCountExcel/" + systemid + "/" + searchTheme;
        let body = {
            searchText: searchData.searchText,
            searchCategory: searchData.searchCategory
        };

        if (searchData.searchSDate) {
            body.searchSDate = searchData.searchSDate;
        }

        if (searchData.searchEDate) {
            body.searchEDate = searchData.searchEDate;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = today + "期交所" + searchTheme + "列印";
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }

    const ScrollToTop = () => {
        useEffect(() => {
            window.scrollTo(0, 500);
        }, []);

        return null;
    };

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }
        function handleClick(i) {
            setCurrentPage(i);
            window.scrollTo(0, 0);
        };

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a key={i + 1} onClick={() => handleClick(i)} className={currentPage === i ? "active" : ""}>{i + 1}</a>
                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }

    return (
            <div className="site-content">
                
                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index" >首頁</a>
                    <i className="fas fa-angle-right"></i><a >統計報表</a>
                    <i className="fas fa-angle-right"></i><strong className="current">各節目及課程影片點擊率</strong>
                </div>

                <div className="inner">
                    <div className="unit-title">
                        <h1>各節目及課程影片點擊率</h1>
                    </div>
                    <div className="widget-box search">
                        <div className="widget-box-header">查詢</div>
                        <div className="widget-box-content">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>類別</th>
                                        <td>
                                            <select name="searchTheme" value={searchTheme} onChange={searchThemeChange}>
                                                <option value="doc">影片</option>
                                                <option value="att">附檔</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="col-2">查詢區間</th>
                                        <td className="col-10">
                                            <div className="date-wrap">
                                                <input type="date"  name="searchSDate" className="hasDatepicker" value={searchData.searchSDate} onChange={handleInputChange} />
                                                    <span>~</span>
                                                <input type="date" name="searchEDate" className="hasDatepicker" value={searchData.searchEDate} onChange={handleInputChange} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>主題類別</th>
                                        <td>                                      
                                            <select name="searchCategory" value={searchData.searchCategory} onChange={handleInputChange}>
                                                <option value="99">全部</option>
                                                {func.map((data) =>
                                                    <option key={data.funcId } value={data.funcId}>{data.funcName}</option>
                                                )}
                                            </select>
                                         </td>
                                    </tr>
                                    
                                    <tr>
                                        <th>關鍵字</th>
                                        <td>
                                            <input type="text" name="searchText" value={searchData.searchText} onChange={handleInputChange} className="col-12" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                        <div className="btn-wrap search">
                        <a onClick={handleSearch } className="search"><i className="fas fa-search"></i>查詢</a>
                        </div>
                        <div className="info-wrap">
                        <p className="search-total">總計<strong>{list.length }</strong>筆資料</p>
                        <div className="btn">
                            <a onClick={handleExcel }>匯出</a>
                            </div>
                        </div>
                        <div className="widget-box">
                        <div className="widget-box-content">
                            {
                                searchTheme === "doc" && 
                                <table className="top-th">
                                    <tbody>
                                        <tr>
                                            <th className="num">項次</th>
                                            <th className="width-250">主題類別</th>
                                            <th>節目名稱</th>
                                            <th className="width-250">點閱次數</th>
                                    </tr>
                                    {currentData.map((data, index) =>
                                        <tr key={index }>
                                            <td>{(currentPage * 10) + (index + 1) }</td>
                                            <td>{data.funcName }</td>
                                            <td className="text-left">{data.docTitle}</td>
                                            <td>{data.docVcounter }</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            }
                            {
                                searchTheme === "att" &&
                                <table className="top-th">
                                    <tbody>
                                        <tr>
                                            <th className="num">項次</th>
                                            <th className="width-250">主題類別</th>
                                            <th>檔案名稱</th>
                                            <th className="width-250">點閱次數</th>
                                        </tr>
                                        {currentData.map((data, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data.funcName}</td>
                                                <td>{data.docTitle}</td>
                                                <td>{data.docVcounter}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                            </div>
                        </div>
                        <Pagination count={list.length} />
                    </div>
                </div>
    )


}
export default From;
