import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


function FromAddBanner() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const systemid = params.get("systemid");

    const [file, setFile] = useState();

    const [formData, setFormData] = useState({
        bannerTitle: '',
        bannerUrl: '',
        bannerImg:'',
        bannerStatus: 0,
        bannerTarget: 1,
        bannerSDate: '',
        bannerEDate: '',
        bannerENever: 1,
    });

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: event.target.value })
    };

    const handleCheckChange = (event) => {
        let changeName = event.target.name
        if (formData.bannerENever===0) {
            setFormData({ ...formData, [changeName]: 1 })
        } else { 
            setFormData({ ...formData, [changeName]: 0 })
        }
    };
    const handleUploadChange = (event) => {
        setFile(event.target.files[0])
        setFormData({ ...formData, "bannerImg": event.target.files[0].name })
    };

    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();

        const upload = new FormData();
        upload.append('bannerTitle', formData.bannerTitle);
        upload.append('bannerUrl', formData.bannerUrl);
        upload.append('bannerImg', formData.bannerImg);
        upload.append('bannerStatus', formData.bannerStatus);
        upload.append('bannerTarget', formData.bannerTarget);
        upload.append('bannerSDate', formData.bannerSDate);
        upload.append('bannerEDate', formData.bannerEDate);
        upload.append('bannerENever', formData.bannerENever);
        upload.append('file', file);
        if (true) {
            fetch('/admin/banner/AddBanner/' + systemid, {
            method: 'POST',
            body: upload,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("新增成功");
                    history.push('/intranet/banner/BannerList?systemid=3&page=1');
                }
                else {
                    alert("新增失敗;" + data.message);
                    history.push('/intranet/banner/BannerList?systemid=3&page=1');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
             console.log("tete");
        }
    }


    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/intranet/banner/BannerList?systemid=3&page=1');
    }

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}

    return (
        <>
                <div className="site-content">

                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")} href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><strong className="current">Banner管理</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>Banner管理</h1>
                        </div>
                        <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST" >
                            <div className="widget-box">
                                <div className="widget-box-header">新增</div>
                                <div className="widget-box-content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th className="col-2">名稱</th>
                                                <td><input type="text" className="col-12" name="bannerTitle" value={formData.bannerTitle} onChange={handleInputChange}  /></td>
                                            </tr>
                                            <tr>
                                            <th className="col-2">上架日期</th>
                                                <td><input type="date" name="bannerSDate" value={formData.bannerSDate} onChange={handleInputChange} /></td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">下架日期</th>
                                                <td><input type="date" name="bannerEDate" value={formData.bannerEDate} onChange={handleInputChange} /><br />
                                                永遠不下架 <input type="checkbox" name="bannerENever" onChange={handleCheckChange} checked={formData.bannerENever === 1} /></td>
                                            </tr>
                                            <tr>
                                                <th width="16%">圖片上傳</th>
                                                <td>
                                                    <input type="file" name="bannerImg" onChange={handleUploadChange} accept="image/png, image/jpeg" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">連結網址</th>
                                                <td><input type="text" className="col-12" name="bannerUrl" value={formData.bannerUrl} onChange={handleInputChange} /></td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">另開視窗</th>
                                                <td>
                                                    <select name="bannerTarget" value={formData.bannerTarget} onChange={handleInputChange} >
                                                        <option value="1">是</option>
                                                        <option value="0">否</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">狀態</th>
                                                <td>
                                                    <select name="bannerStatus" value={formData.bannerStatus} onChange={handleInputChange} >
                                                        <option value="0">停用</option>
                                                        <option value="1">使用中</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="btn-wrap bottom">
                                <a onClick={handleSubmit} className="send" >儲存</a>
                                <a onClick={handlecancel} className="cancel">取消</a>
                            </div>
                        </form>
                    </div>
                </div>
        </>
    )
    

}
export default FromAddBanner;

