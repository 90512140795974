/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';


function NavMenuRWD() {

    const [searchData, setsearchData] = useState([]);

    const [Trader, setTrader] = useState("");
    const [Practitioners, setPractitioners] = useState("");
    const [AdvocateVideo, setAdvocateVideo] = useState("");
    const [EventNews, setEventNews] = useState("");
    const [OtherCourses, setOtherCourses] = useState("");
    const [TaifexLearn, setTaifexLearn] = useState("");

    async function data() {
        const response = await fetch('web/index/getIndexUrl');
        const data = await response.json();

        setTrader(data.find(item => item.funcCode === "Trader").funcInterUrl);
        setPractitioners(data.find(item => item.funcCode === "Practitioners").funcInterUrl);
        setAdvocateVideo(data.find(item => item.funcCode === "AdvocateVideo").funcInterUrl);
        setEventNews(data.find(item => item.funcCode === "EventNews").funcInterUrl);
        setOtherCourses(data.find(item => item.funcCode === "OtherCourses").funcInterUrl);
        setTaifexLearn(data.find(item => item.funcCode === "TaifexLearn").funcInterUrl);
    }

    useEffect(() => {
        data();
    }, []);

    const handleInputChange = (event) => {
        setsearchData(event.target.value)
    };

    const history = useHistory();

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    return (
        <div className="rwd-nav cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left" id="cbp-spmenu-s1">
            <div className="rwd-nav-search">
                <input type="text" name="searchText" value={searchData} onChange={handleInputChange} placeholder="請輸入關鍵字" />
                <div className="rwd-searbtn-wrap">
                    <label className="hide">搜尋</label>
                    <a onClick={() => searchAll()} className="search rwdclose" title="搜尋">搜尋</a>
                    {/*<a onClick={() => google_search()} className="adv-search rwdclose">進階搜尋</a>*/}
                </div>
            </div>
            <nav className="rwd-nav-list">
                <ul className="rwd-nav-main">
                    <li><a title="期貨交易實務課程" style={{ color: "#FFFFFF" }}>期貨交易實務課程<i className="fas fa-chevron-down arr"></i></a>
                        <div className="rwd-nav-sub lev2">
                            <ul>
                                <li><Link to={Trader} className="rwdclose">一般交易人課程</Link></li>
                                <li><Link to={Practitioners} className="rwdclose" >從業人員課程</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li><Link to={AdvocateVideo} className="rwdclose">宣導影片</Link></li>
                    <li><Link to={EventNews} className="rwdclose">活動與新聞</Link></li>
                    <li><Link to={OtherCourses} className="rwdclose">其他課程</Link></li>
                    <li><a href={TaifexLearn} onClick={doLearnCount} title="期貨及選擇權數位學習網" target="_blank" rel="noreferrer">期貨及選擇權數位學習網</a></li>
                </ul>
                <ul className="rwd-nav-misc">
                    <li><a href="/index" >回首頁</a></li>
                    <li><Link to="/Sitmap" className="rwdclose">網站導覽</Link></li>
                </ul>
            </nav>
        </div>
    )

    function searchAll() {
        history.push('/SearchCourseList?search=' + searchData);
    }

    function doLearnCount() {
        const response = fetch('web/index/doLearnCount')
    }

    function google_search() {
        var kw = encodeURIComponent(searchData);
        if (kw.indexOf('.') > -1) {
            kw = kw.replace(".", "%2e");
        }
        if (kw.indexOf('/') > -1) {
            kw = kw.replace("/", "／");
        }
        history.push("/SearchAllList?q=" + kw);
    }

} export default NavMenuRWD;