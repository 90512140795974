import React from 'react';
import { Helmet } from 'react-helmet'



function FooterScript() {

        return (
            <Helmet>
                <script src="/intranet/assets/js/function.js"></script>
            </Helmet>
        )
    
} export default FooterScript;