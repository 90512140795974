import React, { useState, useEffect, useCallback } from 'react'; 
function Message() {

    const [course, setCourse] = useState([]);

    const getCourseNum = useCallback(async () => {
        const response = await fetch('admin/index/getCourseNum');
        const data = await response.json();
        setCourse(data);
    }, []);



    useEffect(() => {
        getCourseNum();
    }, [getCourseNum]);


    return (
        <div className="widget-message">
            <div className="item item1" style={{ backgroundColor: '#ff6452' }}>
                <i className="fas fa-video fa-fw"></i>
                <div className="info-cont">
                    <p className="title">新增期貨交易實務</p>
                    <p className="num">{course.trader}</p>
                </div>
            </div>
            <div className="item item1" style={{ backgroundColor: '#3d5ac3' }}>
                <i className="fas fa-video fa-fw"></i>
                <div className="info-cont">
                    <p className="title">新增宣導影片</p>
                    <p className="num">{course.advocateVideo}</p>
                </div>
            </div>
            <div className="item item1" style={{ backgroundColor: '#00bcd4' }}>
                <i className="fas fa-video fa-fw"></i>
                <div className="info-cont">
                    <p className="title">新增活動與新聞</p>
                    <p className="num">{course.eventNews}</p>
                </div>
            </div>
            <div className="item item2" style={{ backgroundColor: '#ff963e' }}>
                <i className="fas fa-video fa-fw"></i>
                <div className="info-cont">
                    <p className="title">新增其他課程</p>
                    <p className="num">{course.otherCourses}</p>
                </div>
            </div>
        </div>
    )


} export default Message;