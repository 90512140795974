/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import printform from "./Print.js"
import $ from "jquery";
import ReactGA from "react-ga4";

function From() {

    const location = useLocation();
    const history = useHistory();

    const [list, setList] = useState([]);

    useEffect(() => {
        Data();
    }, []);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);

    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/index');
    }

    //GA
    const path = location.pathname + location.search;
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: path, page_title: "智慧財產權限說明" });
    }, [path]);

    return (
        <div className="page-main" id="Print_area">
            <div className="page-kv page-kv-course inner">
                <h2 className="page-kv-title"><strong>智慧財產權限說明</strong></h2>
            </div>

            <div className="crumb">
                <div className="inner crumb-inner">
                    <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >
                    <strong className="current">智慧財產權限說明</strong>
                </div>
            </div>

            <section className="page">
                <div className="inner">
                    <div className="page-header">
                        <div className="page-title">智慧財產權限說明</div>
                        <div className="page-share"><a title="分享" />
                            <ul className="page-share-list">
                                <li>
                                    <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                        <img src="assets/images/fb_icon.png" alt="fb icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                        <img src="assets/images/line_icon.png" alt="line icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                        <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => printform('Print_area', 1280)} title="列印">
                                        <img src="assets/images/print_icon.png" alt="列印 icon" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <strong dangerouslySetInnerHTML={{ __html: list }}></strong>
                    </div>    
                    <div className="page-btn bottom ">
                        <a onClick={handlecancel} className="back " title="回首頁 ">回首頁</a>
                    </div>
                </div>
            </section>
        </div>
    )

    async function Data() {
        const response = await fetch('web/footer/getIntellectual');
        const data = await response.text();
        setList(data);
    }
}
export default From;