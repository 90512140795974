import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function FromEditGroupUser() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const group_id = params.get("id");
    const systemid = params.get("systemid");

    const [userData, setuserData] = useState([]);
    const [formData, setFormData] = useState([]);


    useEffect(() => {
        getdetail();
        getCheck(group_id);
    }, [group_id]);

    useEffect(() => {

    }, [formData]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        const data = formData.includes(value)
            ? formData.filter(item => item !== value)
            : [...formData, value];

        setFormData(data);
    };

    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (true) {
            // Do something with the form data
            fetch('admin/group/EditGroupUser/' + group_id + '/' + systemid, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {

                        alert("修改成功");
                        history.push('/intranet/admin/system/GroupList?systemid=' + systemid +'&page=1');
                    } else {
                        alert("修改失敗");
                        console.log(data.message);
                        history.push('/intranet/admin/system/GroupList?systemid=' + systemid + '&page=1');
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        } else {
            console.log("tete");
        }
    }
    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/intranet/admin/system/GroupList?systemid=' + systemid +'&page=1');
    }

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}

    return (
        <>
                <div className="site-content">

                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a >系統管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">角色及權限管理</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>角色及權限管理 功能</h1>
                        </div>
                        <form onSubmit={handleSubmit} >
                            <div className="widget-box">
                                <div className="widget-box-header">功能</div>
                                <div className="widget-box-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>使用者名稱</th>
                                                <th>異動</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userData.map(username =>
                                                <tr key={username.roleId}>
                                                    <td>{username.roleName}</td>
                                                    <td><input type="checkbox" id={"check_box" + username.roleId} name={"check_box" + username.roleId} value={username.roleId}
                                                        onChange={handleInputChange} checked={formData.includes("" + username.roleId)} /></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="btn-wrap bottom">
                                <a onClick={handleSubmit} className="send" >確認</a>
                                <a onClick={handlecancel} className="cancel">取消</a>
                            </div>
                        </form>
                    </div>
                </div>
        </>
    )

    async function getdetail() {
        const url = ('admin/group/getGroupUserName');
        const response = await fetch(url);
        const data = await response.json();
        var userData = [];
        for (let i = 0; i < data.length; i++) {
            userData.push(data[i]);
        }
        setuserData(userData)
    }
    async function getCheck(id) {
        const url = ('admin/group/getGroupUserCheck/' + id);
        const response = await fetch(url);
        const data = await response.json();
        var checkdata = [];
        for (let i = 0; i < data.length; i++) {
            checkdata.push("" + data[i].roleId);
        }
        setFormData(checkdata);
    }


}
export default FromEditGroupUser;