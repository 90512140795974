/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const systemid = params.get("systemid");

    const [formData, setFormData] = useState([]);
    const [img, setImg] = useState();


    const Data = useCallback(async (systemid) => {
        const response = await fetch('admin/footer/GetFooter/' + systemid);
        const data = await response.json();
        setFormData(data);
    }, []);

    useEffect(() => {
        Data(systemid)
    }, [Data, systemid]);


    const handleInputChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: event.target.value })
        console.log(event.target.value);
        console.log(formData);
    };

    const handleUploadChange = (event) => {
        setImg(event.target.files[0])
    };



    const history = useHistory();

    const handleSubmit = (event) => {
        const upload = new FormData();
        upload.append('TfContent', formData.tfContent);
        upload.append('TfInfo', formData.tfInfo);
        upload.append('file', img);
        fetch('/admin/footer/EditWarn/' + systemid, {
            method: 'POST',
            body: upload,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("修改成功");
                    Data(systemid);
                    history.go(0);
                }
                else {
                    alert("修改失敗;" + data.message);
                    Data(systemid);
                    history.go(0);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const handleclean = (event) => {
        event.preventDefault();
        setFormData({ tfContent: "" })
    }

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
    //onClick={(event) => handlego(event,"/intranet/index")}

    return (
        <>
                <div className="site-content">

                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")} href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a >網站內容管理</a>
                        <i className="fas fa-angle-right"></i><a >Footer管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">課程警語</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>課程警語</h1>
                        </div>
                        <div className="widget-box search">
                            <div className="widget-box-content">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th width="14%">警告文字</th>
                                            <td>
                                                <textarea className="col-12" rows="5" name='tfContent' value={formData.tfContent} onChange={handleInputChange} maxlength="150"></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th width="14%">圖片</th>
                                            <td>
                                                <input type="file" name='tfImg' onChange={handleUploadChange} accept="image/png, image/jpeg" /><span>{formData.tfFilename}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="btn-wrap bottom">
                            <a onClick={(event) => handleSubmit(event)} className="certain">儲存</a>
                            {/*<a onClick={handleclean} className="clear">清除</a>*/}
                        </div>
                    </div>
                </div>
        </>
    )


}
export default From;