import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function FromEditGroupFunc() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const group_id = params.get("id");
    const systemid = params.get("systemid");

    const [funcData, setfuncData] = useState([]);
    const [formData, setFormData] = useState([]);

    async function getdetail() {
        const url = ('admin/group/getGroupFuncName');
        const response = await fetch(url);
        const data = await response.json();
        var funcNamedata = [];
        for (let i = 0; i < data.length; i++) {
            funcNamedata.push(data[i]);
        }
        setfuncData(funcNamedata)
    }
    async function getCheck(id) {
        const url = ('admin/group/getGroupFuncCheck/' + id);
        const response = await fetch(url);
        const data = await response.json();
        var checkdata = [];
        for (let i = 0; i < data.length; i++) {
            checkdata.push("" + data[i].funcId);
        }
        setFormData(checkdata);
    }

    useEffect(() => {
        getdetail();
        getCheck(group_id);
    }, [group_id]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        const data = formData.includes(value)
            ? formData.filter(item => item !== value)
            : [...formData, value];

        setFormData(data);
    };

    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (true) {
            // Do something with the form data
            fetch('admin/group/EditGroupFunc/' + group_id + '/' + systemid , {
                method: 'POST',
                body: JSON.stringify( formData),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert("修改成功");
                        history.push('/intranet/admin/system/GroupList?systemid=' + systemid +'&page=1');
                    } else {
                        alert("修改失敗");
                        console.log(data.message);
                        history.push('/intranet/admin/system/GroupList?systemid=' + systemid + '&page=1');
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        } else {
            console.log("tete");
        }
    }
    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/intranet/admin/system/PersonRole?systemid=' + systemid + '&page=1');
    }
    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}

    return (
        <>
                <div className="site-content">

                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a>系統管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">個人權限</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                        <h1>個人權限 功能</h1>
                        </div>
                        <form onSubmit={handleSubmit} >
                            <div className="widget-box">
                                <div className="widget-box-header">功能</div>
                                <div className="widget-box-content">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>功能名稱</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {funcData.map(funcname =>
                                            formData.includes("" + funcname.funcId) ?
                                            
                                                <tr key={funcname.funcId }>
                                                    <td>{funcname.funcName}</td>
                                                </tr>
                                            : ""
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="btn-wrap bottom">
                                <a onClick={handlecancel} className="cancel">返回</a>
                            </div>
                        </form>
                    </div>
                </div>
        </>
    )




}
export default FromEditGroupFunc;


