import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

function From() {

    const [Info, setInfo] = useState([]);
    const [Count, setCount] = useState([]);
    const [WebImg, setWebImg] = useState([]);

    useEffect(() => {
        Data();
        getIndexCount();
        imgurl();
    }, []);

    async function imgurl() {
        const response = await fetch('web/setting/GetWebImg');
        const data = await response.text();
        setWebImg(data);
    }

    return (
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-left">
                        <ul className="list">
                        <li><a href="mailto:service@taifex.com.tw" title="聯絡我們" target="_blank" rel="noopener noreferrer">聯絡我們</a></li>
                        <li><Link to="/Intellectual" className="more-btn">智慧財產權權限說明</Link></li>
                        <li><a href="https://www.taifex.com.tw/cht/index" title="回臺灣期貨交易所" target="_blank" rel="noopener noreferrer">回臺灣期貨交易所</a></li>
                        </ul>
                    
                        <div className="footer-info" dangerouslySetInnerHTML={{ __html: Info.tfContent }} />

                    </div>
                <div className="footer-right">
                    <img src={WebImg+ "ContactInfo/" + Info.qrcode}  alt={Info.qrcodeName} className="footer-img" />
                    <p>瀏覽人數： {Count }</p>
                    </div>
                </div>
                <div className="footer-cont">
                    <div className="foot">
                        <p>Copyright © 臺灣期貨交易所 Taiwan Futures Exchange</p>
                    </div>
                </div>
            </footer>
    )

    async function Data() {
        const response = await fetch('web/footer/getFootInfo/');
        const data = await response.json();
        setInfo({ "tfContent": data.tfContent, "qrcode": data.tfFile, "qrcodeName": data.tfFileName });
    }
    async function getIndexCount() {
        const response = await fetch('web/footer/getIndexCount/');
        const data = await response.json();
        setCount(data);
    }
    
} export default From;