import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function FromAddRole() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const systemid = params.get("systemid");

    const [deptlist, setdeptlist] = useState([]);
    const [formData, setFormData] = useState({
        roleAcc: '',
        roleName: '',
        roleEmail: '',
        roleEmpno: '',
        roleDept: '',
        roleStatus: 0,
    });

    const history = useHistory();

    useEffect(() => {
        deptData();
    }, []);


    const handleInputChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: event.target.value })
    };

    const handleRadioChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: parseInt(event.target.value) })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (true) {
            // Do something with the form data
            fetch('/admin/role/AddRole/' + systemid, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {

                        alert("新增成功");
                        history.push('/intranet/admin/system/RoleList?systemid=' + systemid +'&page=1');
                    } else {
                        alert("新增失敗");
                        console.log(data.message);
                        history.push('/intranet/admin/system/RoleList?systemid=' + systemid +'&page=1');
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            console.log("tete");
        }
    }
    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/intranet/admin/system/RoleList?systemid=' + systemid +'&page=1');
    }

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    async function deptData() {
        const response = await fetch('admin/role/GetDept');
        const data = await response.json();
        setdeptlist(data);
    }

    return (
        <>

                <div className="site-content">


                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a >系統管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">使用者管理</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>使用者管理</h1>
                        </div>
                        <form onSubmit={handleSubmit} >
                            <div className="widget-box">
                                <div className="widget-box-header">新增</div>
                                <div className="widget-box-content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th className="col-2">使用者帳號</th>
                                                <td><input type="text" className="col-12" name="roleAcc" value={formData.roleAcc} onChange={handleInputChange} /></td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">使用者名稱</th>
                                                <td><input type="text" className="col-12" name="roleName" value={formData.roleName} onChange={handleInputChange} /></td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">使用者信箱</th>
                                                <td><input type="text" className="col-12" name="roleEmail" value={formData.roleEmail} onChange={handleInputChange} /></td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">員工編號</th>
                                                <td><input type="text" className="col-12" name="roleEmpno" value={formData.roleEmpno} onChange={handleInputChange} /></td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">使用者部門</th>
                                                < td >
                                                    <select name="roleDept" value={formData.roleDept} onChange={handleInputChange} >
                                                        <option value="x">請選擇</option>
                                                            {deptlist.map((dept, index) =>
                                                                <option key={index} value={dept.deptId} >{dept.deptName}</option>
                                                            )}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-2">狀態</th>
                                                <td>
                                                    <div className="form-inline">
                                                    <div><input type="radio" name="roleStatus" id="OTn" value="1" onChange={handleRadioChange} checked={formData.roleStatus === 1} /><label>啟用</label></div>
                                                    <div><input type="radio" name="roleStatus" id="OTy" value="0" onChange={handleRadioChange} checked={formData.roleStatus === 0} /><label>停用</label></div>  
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="btn-wrap bottom">
                                <a onClick={handleSubmit} className="send" >儲存</a>
                                <a onClick={handlecancel} className="cancel">取消</a>
                            </div>
                        </form>
                    </div>
                </div>
        </>
    )

}
export default FromAddRole;


