/* eslint-disable no-undef */
import React, { useEffect, useCallback, useRef } from 'react';
import loadGoogleCharts from './googleCharts';

function FuncCountYear() {
    const chartRef = useRef(null);

    const getCourseNum = useCallback(async () => {
        const response = await fetch('admin/index/getFuncCountYear');
        const data = await response.json();

        const chartData = [['Month', '交易人員課程', '從業人員課程', '宣導影片', '活動新聞', '其他課程']];

        data.forEach(item => {
            chartData.push(item.count);
        });


        const options = {  
            hAxis: {
                ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                gridlines: {
                    color: 'transparent', // 设置网格线颜色为透明
                },
            },
            vAxis: {
                title: '點閱率',

            },
            chartArea: {
                width: '80%', // 设置图表区域宽度
                height: '80%', // 设置图表区域高度
            },
            legend: { position: 'top' },
        };


        try {
            await loadGoogleCharts();

            // 使用Google Charts库创建图表
            const dataTable = google.visualization.arrayToDataTable(chartData);
            const chart = new google.visualization.LineChart(chartRef.current);
            chart.draw(dataTable, options);

        } catch (error) {
            console.error('Error loading Google Charts:', error);
        }

    }, []);

    useEffect(() => {
        getCourseNum();
    }, [getCourseNum]);

    return (
        <div ref={chartRef} style={{ 'height': 400 }} className="widget-box-content"></div>
    )

} export default FuncCountYear;