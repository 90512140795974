import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import  ZhTWHome  from './components/Home';
import Login from './components/intranet/Login';
import GroupSelect from './components/intranet/GroupSelect';
import  IntraHome  from './components/intranet/Home';
import { Head as ZhTWHead } from './components/Head';
import { Head as IntraHead } from './components/intranet/Head';


function App() {


        return (
            <>
                <Switch>
                    <Route path='/intranet' component={IntraHead} />
                    <Route path='/' component={ZhTWHead} />
                </Switch>
                <Switch>
                    <Route path='/intranet/login' component={Login} />
                    <Route path='/intranet/GroupSelect' component={GroupSelect} />
                    <Route path='/intranet/' component={IntraHome} />
                    <Redirect exact from='/' to='/index' />
                    <Route path='/' component={ZhTWHome} />
                </Switch>
            </>
        );
}
export default App;

