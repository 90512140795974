import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function FromAddGroup() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const systemid = params.get("systemid");

    const [formData, setFormData] = useState({
        groupName: '',
    });

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: event.target.value })
    };

    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (true) {
            // Do something with the form data
            fetch('admin/group/AddGroup/' + systemid, {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {

                        alert("新增成功");
                        history.push('/intranet/admin/system/GroupList?systemid='+systemid+'&page=1');
                    } else {

                        console.log(data.message);
                    }
                })
                .catch((error) => {
                    console.log('Error:'+ error);
                });
        } else {
            console.log("tete");
        }
    }
    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/intranet/admin/system/GroupList?systemid=' + systemid +'&page=1');
    }
    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}
    return (
        <>
                <div className="site-content">
                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event,"/intranet/index")} href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a>系統管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">角色及權限管理</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>角色及權限管理 新增</h1>
                        </div>
                        <form onSubmit={handleSubmit} >
                            <div className="widget-box">
                                <div className="widget-box-header">新增</div>
                                <div className="widget-box-content">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th className="col-2">群組名稱</th>
                                                <td>
                                                    <input type="text" className="col-12" name="groupName" value={formData.groupName} onChange={handleInputChange} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="btn-wrap bottom">
                                <a onClick={handleSubmit} className="send" >新增</a>
                                <a onClick={handlecancel} className="cancel">取消</a>
                            </div>
                        </form>
                    </div>
                </div>
        </>
    )

}
export default FromAddGroup;