/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [func, setFunc] = useState([]);

    const [intranetLoginAcc, setIntranetLoginAcc] = useState(null);

    const [searchData, setsearchData] = useState({
        searchGroup: '',
        searchAcc: '',
    });


    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料

    useEffect(() => {
        fetch('admin/login/logininfo')
            .then(response => response.json())
            .then(data => {
                if (!data || !data.roleName) {

                } else {
                    setIntranetLoginAcc(data);
                    console.log(data.roleId);
                    Data(data.roleId);
                }
            });
    }, []);

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })
    };
    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}
    return (
            <div className="site-content">

                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index" >首頁</a>
                    <i className="fas fa-angle-right"></i><a >系統管理</a>
                    <i className="fas fa-angle-right"></i><strong className="current">個人權限</strong>
                </div>

                <div className="inner">
                    <div className="unit-title">
                    <h1>個人權限</h1>
                    </div>
                    <div className="info-wrap">
                        <p className="search-total">總計<strong>{list.length}</strong>筆資料</p>
                    </div>
                    <div className="widget-box">
                        <div className="widget-box-content">
                            <table className="top-th">
                                <tbody>
                                    <tr>
                                        <th className="num">序號</th>
                                        <th>帳號</th>
                                        <th>角色</th>
                                        <th>功能</th>
                                    </tr>
                                    {currentData.map((data,index)=>
                                        <tr key={index}>
                                            <td className="text-center">{(currentPage * 10) + (index + 1)}</td>
                                            <td>{data.roleAcc }</td>
                                            <td>{data.groupName }</td>
                                            <td><a className="table-btn" onClick={(event) => handlego(event, "./PersonRoleFunc?systemid=" + systemid + "&id=" + data.groupId)} ><i className="fas fa-cog icon"></i>功能</a></td>
                                        </tr>
                                    )}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination count={list.length} />
                </div>
            </div>
    )

    async function Data(id) {

        const response = await fetch('admin/personRole/getPersonRole/'+id);
        const data = await response.json();
        setList(data);

    }

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a key={i + 1} onClick={() => setCurrentPage(i)} className={currentPage === i ? "active" : ""}>{i + 1}</a>

                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }
}
export default From;
