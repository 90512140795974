/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [func, setFunc] = useState([]);

    const [searchData, setsearchData] = useState({
        searchText: '',
        searchStatus: 99,
    });

    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料



    const funcData = useCallback(async () => {
        const response = await fetch('admin/doc/getFunc/' + systemid);
        const data = await response.json();
        setFunc(data);
    }, [systemid]);

    useEffect(() => {
        funcData();
        Data(systemid);
        setCurrentPage(0);
    }, [funcData, systemid]);

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })
    };

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
    async function Data(systemid) {

        const response = await fetch('admin/doc/getCourse/' + systemid);
        const data = await response.json();
        setList(data);
    }


    function handleDelete(event, id) {

        let url = "admin/doc/DeleteDoc/" + systemid + "/" + id;

        if (confirm("要將此影片刪除嗎?")) {
            // Do something with the form data
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert("刪除成功");
                        Data(systemid);
                    } else {

                        console.log(data.message);
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        }
    }

    function handleSearch(event) {
        event.preventDefault();
        let url = "admin/doc/SearchCourse/" + systemid;
        // Do something with the form data
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(searchData),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                setList(data);
                setCurrentPage(0);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        function handleClick(i) {
            setCurrentPage(i);
            window.scrollTo(0, 0);
        };

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a key={i + 1} onClick={() => handleClick(i)} className={currentPage === i ? "active" : ""}>{i + 1}</a>
                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }

    return (
        <>
                <div className="site-content">
                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")} href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a >網站內容管理</a>
                        <i className="fas fa-angle-right"></i><a >主選單管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">{func.funcName}</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>{func.funcName}</h1>
                        </div>
                        <div className="widget-box search">
                            <div className="widget-box-header">查詢</div>
                            <div className="widget-box-content">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th className="col-2">狀態</th>
                                            <td className="col-10">
                                                <select name="searchStatus" value={searchData.searchStatus} onChange={handleInputChange} >
                                                    <option value="99">全部</option>
                                                    <option value="0">不顯示</option>
                                                    <option value="1">顯示</option>
                                                    <option value="2">顯示Live</option>
                                                    <option value="3">非公開</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>影片名稱</th>
                                            <td>
                                                <input type="text" className="col-12" name="searchText" value={searchData.searchText} onChange={handleInputChange} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="btn-wrap search">
                            <a href="fadf" className="search" onClick={handleSearch}><i className="fas fa-search"></i>查詢</a>
                        </div>
                        <div className="info-wrap">
                            <p className="search-total">總計<strong>{list.length}</strong>筆資料</p>
                            <div className="btn">
                                <a onClick={(event) => handlego(event, "/intranet/video/AddCourse?systemid=" + systemid)} href={"./intranet/video/AddCourse?systemid=" + systemid}>新增</a>
                            </div>
                        </div>
                        <div className="widget-box">
                            <div className="widget-box-content">
                                <table className="top-th">
                                    <tbody>
                                        <tr>
                                            <th className="num">序號</th>
                                            <th>影片名稱</th>
                                            <th>說明</th>
                                            <th>上下架時間</th>
                                            <th className="width-100">狀態</th>
                                            <th className="width-250">操作</th>
                                        </tr>
                                        {currentData.map((data, index) =>
                                            <tr key={data.docId}>
                                                <td>{(currentPage * 10) + (index + 1)}</td>
                                                <td >{data.docTitle}</td>
                                                <td >{data.docContent}</td>
                                                {
                                                    data.docEDate != null ? <td>{data.docSDate.substring(0, 10)}<br />~<br />{data.docEDate.substring(0, 10)}</td> :
                                                        <td>{data.docSDate.substring(0, 10)}<br />~<br />永不下架</td>
                                                }
                                                <td>{data.docStatus === 0 && "不顯示"}{data.docStatus === 1 && "顯示"}{data.docStatus === 2 && "顯示Live"}{data.docStatus === 3 && "非公開"}</td>
                                                <td className="tb-btn-wrap">
                                                    <a className="table-btn" onClick={(event) => handlego(event, "./EditCourse?systemid=" + systemid + "&id=" + data.docId)} ><i className="fas fa-edit icon"></i>編輯</a>
                                                    <a className="table-btn" onClick={(event) => handleDelete(event, data.docId)} ><i className="fas fa-trash-alt icon"></i>刪除</a>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination count={list.length} />
                    </div>
                </div>
        </>
    )
}
export default From;


