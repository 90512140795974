/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function FormLogin() {

    // 使用 state 管理 CAPTCHA 的狀態
    //期交所測試先關掉
    //const [isVerified, setIsVerified] = useState(false);
    const [isVerified, setIsVerified] = useState([]);
    const history = useHistory();

    const [formData, setFormData] = useState({
        acc_id: '',
        acc_pw: '',
    });

    const [Setting, setSetting] = useState({
        key : '',
    });

    useEffect(() => {
        GetCAPTCHA();

        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js?render=' + Setting.key;
        script.async = true;
        document.head.appendChild(script);

    }, [Setting.key]);

    useEffect(() => {
        GetReCaptcha_open();
    }, []);

    async function GetCAPTCHA() {
        try {
            const response = await fetch('web/setting/GetCAPTCHA');
            const data = await response.text();
            setSetting({ key: data });
        } catch (error) {
            console.log(error);
        }
    }

    async function GetReCaptcha_open() {
        try {
            const response = await fetch('web/setting/GetReCaptcha_open');
            const data = await response.text();
            setIsVerified(data);
        } catch (error) {
            console.log(error);
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (isVerified === "true") {

            grecaptcha.enterprise.ready(async () => {
                const token2 = await grecaptcha.enterprise.execute(Setting.key, { action: 'LOGIN' });
                const upload = new FormData();
                upload.append('token', token2);
                fetch('/admin/login/re', {
                    method: 'POST',
                    body: upload,

                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            //alert("驗證成功");
                            setIsVerified(true);
                            console.log("CAPTCHA verified");
                            // Do something with the form data
                            fetch('/admin/login', {
                                method: 'POST',
                                body: JSON.stringify(formData),
                                headers: { 'Content-Type': 'application/json' },
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.success) {
                                        // 登入驗證成功，跳轉到首頁
                                        history.push('/intranet/GroupSelect');
                                    } else {
                                        // 登入驗證失敗，顯示錯誤訊息
                                        alert(data.message);
                                        console.log(data.message);
                                        history.go(0);
                                    }
                                })
                                .catch((error) => {
                                    console.error('Error:', error);
                                });
                        } else {
                            alert("驗證失敗");
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            });
        } else {
            
            // Do something with the form data
            fetch('/admin/login', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        // 登入驗證成功，跳轉到首頁
                        history.push('/intranet/index');
                    } else {
                        // 登入驗證失敗，顯示錯誤訊息
                        alert(data.message);
                        console.log(data.message);
                        history.go(0);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    return (
        <>
            <Helmet>
                <title>臺灣期貨交易所-期貨影音知識網管理平台</title>
                <meta name="keywords" content="" />
                <link rel="shortcut icon" href="/intranet/assets/favicons/favicon2.ico" />
                <link rel="stylesheet" href="/intranet/assets/css/normalize.css" />
                <link rel="stylesheet" href="/intranet/assets/css/all.css" />
                <link rel="stylesheet" href="/intranet/assets/css/jquery.fancybox.min.css" />
                <link rel="stylesheet" href="/intranet/assets/css/material-icons.css" />
                <link rel="stylesheet" href="/intranet/assets/css/jquery-ui.css" />
                <link rel="stylesheet" href="/intranet/assets/css/basic.css" />
                <link rel="stylesheet" href="/intranet/assets/css/page.css" />
                <link rel="stylesheet" href="/intranet/assets/css/index.css" />
                <link rel="stylesheet" href="/intranet/assets/css/responsive.css" />
            </Helmet>
            <div className="login">
                
                <form onSubmit={handleSubmit}>
                    <section className="login-box">
                        <header className="login-header">
                            <div className="logo"><small>臺灣期貨交易所</small> 期貨影音知識網管理平台</div>
                        </header>
                        <ul className="login-form list">
                            <li>
                                <label className="login-item col-3"><i className="fas fa-pen"></i>帳號</label>
                                <div className="login-content col-9">
                                    <input type="text" name="acc_id" value={formData.acc_id} onChange={handleInputChange} />
                                </div>
                            </li>
                            <li>
                                <label className="login-item col-3"><i className="fas fa-key"></i>密碼</label>
                                <div className="login-content col-9">
                                    <input type="password" name="acc_pw" value={formData.acc_pw} onChange={handleInputChange} />
                                </div>
                            </li>
                        </ul>
                        <div className="btn-wrap">
                            <a onClick={handleSubmit} className="send" >送出</a>
                            <a href="intranet/login" className="cancel">取消</a>
                        </div>
                    </section>
                </form>
            </div>
            <footer id="footer">
                <div className="inner">
                    建議使用：IE11以上或Chrome、Firefox瀏覽器
                </div>
            </footer>
        </>
    )
}

export default FormLogin;

class IntranetLoginAcc {
    constructor(RoleId, RoleAcc, RoleDept, RoleName, RoleGroup) {
        this.RoleId = RoleId;
        this.RoleAcc = RoleAcc;
        this.RoleDept = RoleDept;
        this.RoleName = RoleName;
        this.RoleGroup = RoleGroup;
    }
}