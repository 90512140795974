/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [func, setFunc] = useState([]);


    const [searchData, setsearchData] = useState({

        searchName: '',
        searchSDate: '',
        searchShh: '00',
        searchSmm: '00',
        searchEDate: '',
        searchEhh: '00',
        searchEmm: '00',
        searchIp: '',
        searchText: '',
    });


    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料


    useEffect(() => {
        Data();
    }, []);

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })
    };

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}
    return (
            <div className="site-content">

                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")} href="/intranet/index" >首頁</a>
                    <i className="fas fa-angle-right"></i><a >系統管理</a>
                    <i className="fas fa-angle-right"></i><strong className="current">使用者操作紀錄</strong>
                </div>

                <div className="inner">
                    <div className="unit-title">
                        <h1>使用者操作紀錄</h1>
                    </div>
                    <div className="widget-box search">
                        <div className="widget-box-header">查詢</div>
                        <div className="widget-box-content">
                            <table>
                                <tbody>
                                    <tr>
                                        <th className="col-2">
                                            使用者帳號/姓名
                                        </th>
                                        <td className="col-10">
                                            <input type="text" name="searchName" value={searchData.searchName} onChange={handleInputChange}  />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            搜尋區間
                                        </th>
                                        <td>
                                            <div className="date-wrap">
                                                <div>
                                                    <input type="date" name="searchSDate" className="hasDatepicker" value={searchData.searchSDate} onChange={handleInputChange}  />
                                                        <select name="searchShh" value={searchData.searchShh} onChange={handleInputChange}>
                                                            <option value="00">00</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                        </select>時
                                                        <select name="searchSmm" value={searchData.searchSmm} onChange={handleInputChange}>
                                                            <option value="00">00</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                            <option value="31">31</option>
                                                            <option value="32">32</option>
                                                            <option value="33">33</option>
                                                            <option value="34">34</option>
                                                            <option value="35">35</option>
                                                            <option value="36">36</option>
                                                            <option value="37">37</option>
                                                            <option value="38">38</option>
                                                            <option value="39">39</option>
                                                            <option value="40">40</option>
                                                            <option value="41">41</option>
                                                            <option value="42">42</option>
                                                            <option value="43">43</option>
                                                            <option value="44">44</option>
                                                            <option value="45">45</option>
                                                            <option value="46">46</option>
                                                            <option value="47">47</option>
                                                            <option value="48">48</option>
                                                            <option value="49">49</option>
                                                            <option value="50">50</option>
                                                            <option value="51">51</option>
                                                            <option value="52">52</option>
                                                            <option value="53">53</option>
                                                            <option value="54">54</option>
                                                            <option value="55">55</option>
                                                            <option value="56">56</option>
                                                            <option value="57">57</option>
                                                            <option value="58">58</option>
                                                            <option value="59">59</option>
                                                        </select>分
                                                </div>
                                                <span> ~ </span>
                                                <div>
                                                    <input type="date" name="searchEDate" className="hasDatepicker" value={searchData.searchEDate} onChange={handleInputChange}  />
                                                        <select name="searchEhh" value={searchData.searchEhh} onChange={handleInputChange} >
                                                            <option value="00">00</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                        </select>時
                                                        <select name="searchEmm" value={searchData.searchEmm} onChange={handleInputChange} >
                                                            <option value="00">00</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                            <option value="24">24</option>
                                                            <option value="25">25</option>
                                                            <option value="26">26</option>
                                                            <option value="27">27</option>
                                                            <option value="28">28</option>
                                                            <option value="29">29</option>
                                                            <option value="30">30</option>
                                                            <option value="31">31</option>
                                                            <option value="32">32</option>
                                                            <option value="33">33</option>
                                                            <option value="34">34</option>
                                                            <option value="35">35</option>
                                                            <option value="36">36</option>
                                                            <option value="37">37</option>
                                                            <option value="38">38</option>
                                                            <option value="39">39</option>
                                                            <option value="40">40</option>
                                                            <option value="41">41</option>
                                                            <option value="42">42</option>
                                                            <option value="43">43</option>
                                                            <option value="44">44</option>
                                                            <option value="45">45</option>
                                                            <option value="46">46</option>
                                                            <option value="47">47</option>
                                                            <option value="48">48</option>
                                                            <option value="49">49</option>
                                                            <option value="50">50</option>
                                                            <option value="51">51</option>
                                                            <option value="52">52</option>
                                                            <option value="53">53</option>
                                                            <option value="54">54</option>
                                                            <option value="55">55</option>
                                                            <option value="56">56</option>
                                                            <option value="57">57</option>
                                                            <option value="58">58</option>
                                                            <option>59</option>
                                                        </select>分
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            登入IP
                                        </th>
                                        <td>
                                            <input type="text" name="searchIp" value={searchData.searchIp} onChange={handleInputChange} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            關鍵字搜尋
                                        </th>
                                        <td>
                                            <input type="text" className="col-12" name="searchText" value={searchData.searchText} onChange={handleInputChange}  />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="btn-wrap search">
                        <a onClick={handleSearch} className="search"><i className="fa-solid fa-magnifying-glass"></i>查詢</a>
                    </div>
                    <div className="info-wrap">
                        <p className="search-total">總計<strong>{list.length}</strong>筆資料</p>
                    </div>
                    <div className="widget-box">
                        <div className="widget-box-content">
                            <table className="top-th">
                                <tbody>
                                    <tr>
                                        <th className="num">項次</th>
                                        <th>日期</th>
                                        <th>使用者帳號</th>
                                        <th>使用者姓名</th>
                                        <th>IP</th>
                                        <th>內容</th>
                                    </tr>
                                    {currentData.map((data,index)=>             
                                        <tr key={index } >
                                            <td className="text-center">{(currentPage * 10) + (index + 1) }</td>
                                            <td>{data.createDate }</td>
                                            <td>{data.roleAcc}</td>
                                            <td>{data.operatorName}</td>
                                            <td>{data.ip}</td>
                                            <td>{data.action}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Pagination count={list.length} />
                </div>
            </div>
    )

    async function Data() {

        const response = await fetch('admin/log/getUserLog');
        const data = await response.json();
        setList(data);

    }

    function handleSearch(event) {
        event.preventDefault();
        let url = "admin/log/getLogSearch/" + systemid;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(searchData),
            headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => {
            setList(data);
            setCurrentPage(0);
        })
        .catch((error) => {
            console.log('Error:' + error);
        });

    }

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a key={i + 1} onClick={() => setCurrentPage(i)} className={currentPage === i ? "active" : ""}>{i + 1}</a>

                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }
}
export default From;
