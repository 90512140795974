/* eslint-disable no-unreachable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation,Link } from 'react-router-dom';
import printform from "./Print.js"
import $ from "jquery";
import ReactGA from "react-ga4";

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    useEffect(() => {
        Data();
    }, []);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);

    //GA
    const path = location.pathname + location.search;
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: path, page_title: "網站導覽"  });
    }, [path]);

    return (
        <div className="page-main" id="Print_area">
            <div className="page-kv page-kv-course inner">
                <h2 className="page-kv-title"><strong>網站導覽</strong></h2>
            </div>

            <div className="crumb">
                <div className="inner crumb-inner">
                    <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >
                    <strong className="current">網站導覽</strong>
                </div>
            </div>

            <section className="page">
                <div className="inner">
                    <div className="page-header">
                        <div className="page-title">網站導覽</div>
                        <div className="page-share"><a  title="分享" />
                        <ul className="page-share-list">
                            <li>
                                <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                    <img src="assets/images/fb_icon.png" alt="fb icon" />
                                </a>
                            </li>
                            <li>
                                <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                    <img src="assets/images/line_icon.png" alt="line icon" />
                                </a>
                            </li>
                            <li>
                                <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                    <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                </a>
                            </li>
                            <li>
                                <a onClick={() => printform('Print_area', 1280)} title="列印">
                                    <img src="assets/images/print_icon.png" alt="列印 icon" />
                                </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <div className="sitemap">
                        {/*<div className="accessibility">*/}
                        {/*    <p>本網站依『無障礙網頁開發規範』設計原則而建置，遵循無障礙網站設計之規範，提供網頁導盲磚(:::)、網站導覽 (Site Navigator)、鍵盤快速鍵 (Access Key) 等設計方式。</p>*/}
                        {/*    <h3 className="title">本網站的主要區塊內容及快速鍵(Accesskey)設定如下：</h3>*/}
                        {/*    <ul className="num-list">*/}
                        {/*        <li><span className="accessibility-color">上方導覽區(Alt+U)</span><br />*/}
                        {/*            <span className="accessibility-color">站內查詢區(Alt+Z)</span>：站內關鍵字查詢。</li>*/}
                        {/*        <li><span className="accessibility-color">左方功能區(Alt+L)</span>：此區塊列有單元的次要連結。</li>*/}
                        {/*        <li><span className="accessibility-color">中央內容區(Alt+C)</span>：為本站主要內容區，點選任一連結之後，其所有內容都會呈現在本區。</li>*/}
                        {/*    </ul>*/}
                        {/*    <h3 className="title">各瀏覽器快速鍵操作說明：</h3>*/}
                        {/*    <ul className="sitemap_list">*/}
                        {/*        <li>Internet Explorer 請使用Alt加上快速鍵代碼操作。</li>*/}
                        {/*        <li>Firefox 請使用Alt+Shift加上快速鍵代碼操作。</li>*/}
                        {/*        <li>Google Chrome 若作業系統為Windows請使用Alt加上快速鍵代碼操作，若為Mac則使用Alt+Option加上快速鍵代碼操作。</li>*/}
                        {/*        <li>Opera 請使用Shift+Esc加上快速鍵代碼操作。</li>*/}
                        {/*        <li>Safari 若作業系統為Windows請使用Alt加上快速鍵代碼操作，若作業系統為Mac則使用Alt+Option加上快速鍵代碼操作。 </li>*/}
                        {/*    </ul>*/}
                        {/*    <h3 className="title">常用鍵盤快速鍵說明：</h3>*/}
                        {/*    <ul className="sitemap_list">*/}
                        {/*        <li>← → or ↑↓：頁面上下左右移動。</li>*/}
                        {/*        <li>Home or End：跳至頁面的最上方或最下方。</li>*/}
                        {/*        <li>鍵盤Tab鍵：下一個選項。</li>*/}
                        {/*        <li>鍵盤Tab鍵+Shift鍵：回上一個選項。</li>*/}
                        {/*        <li>Ctrl+P:列印網頁。</li>*/}
                        {/*        <li>Ctrl+"加號鍵"或Ctrl+"減號鍵":放大或縮小頁面字級。</li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                        <div className="sitemap-cont">
                            <div dangerouslySetInnerHTML={{ __html: list }} />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

    async function Data() {
        const response = await fetch('web/index/getSitmap');
        const data = await response.text();
        setList(data);
    }

}
export default From;