/* eslint-disable no-undef */
import React, { useEffect, useCallback, useRef } from 'react';
import loadGoogleCharts from './googleCharts';

function FuncCountNum() {
    const chartRef = useRef(null);

    const getFuncCountNum = useCallback(async () => {
        const response = await fetch('admin/index/getFuncCountNum');
        const data = await response.json();

        const chartData = [['label', 'count']];
        data.forEach(item => {
            chartData.push([item.label, item.count]);
        });

        const options = {
            chartArea: {
                width: '90%', // 设置图表区域宽度
                height: '90%', // 设置图表区域高度
            },
            sliceVisibilityThreshold: 0, 
        };

        try {
            await loadGoogleCharts();

            // 使用Google Charts库创建图表
            const chart = new google.visualization.PieChart(chartRef.current);
            chart.draw(google.visualization.arrayToDataTable(chartData), options);

        } catch (error) {
            console.error('Error loading Google Charts:', error);
        }
    }, []);

    useEffect(() => {
        getFuncCountNum();
    }, [getFuncCountNum]);

    return (
        <div ref={chartRef} style={{ 'height': 400 }} className="widget-box-content"></div>
    )
} export default FuncCountNum;