import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Banner() {

    const [banner, setbanner] = useState([]);
    const [WebImg, setWebImg] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        updateViewport();
        window.addEventListener('resize', updateViewport);
        return () => {
            window.removeEventListener('resize', updateViewport);
        };
    }, []);

    const updateViewport = () => {
        const mobile = window.innerWidth <= 768;
        setIsMobile(mobile);
    };

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            ><img src='assets/images/kv-arr1.png' alt="Prev" /></div>
        );
    }

    function NextArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
            ><img src='assets/images/kv-arr2.png' alt="Next" /></div>
        );
    }


    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        /*fade: true,*/
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: !isMobile
                }
            }
        ]
    };

    useEffect(() => {
        Data();
        imgurl();
    }, []);

    async function Data() {
        const response = await fetch('web/index/getBanner');
        const data = await response.json();
        setbanner(data);
    }

    async function imgurl() {
        const response = await fetch('web/setting/GetWebImg');
        const data = await response.text();
        setWebImg(data);
    }
    return (
        <section className="index-kv">
            <Slider {...settings}>
                {banner.map((data, index) =>

                    data.bannerUrl === '' || data.bannerUrl === null ?
                        <span key={index}><img src={WebImg+"banner/" + data.bannerFile} alt={data.bannerTitle} /></span>
                        :
                        data.bannerTarget === 1 ?
                            <a className="banner-image" href={data.bannerUrl} title={data.bannerTitle} key={index} target="_blank" rel="noreferrer" ><img src={WebImg + "banner/" + data.bannerFile} alt={data.bannerTitle} /></a>
                            :
                            <a className="banner-image" href={data.bannerUrl} title={data.bannerTitle} key={index}  ><img src={WebImg + "banner/" + data.bannerFile} alt={data.bannerTitle} /></a>
                )}
            </Slider>
        </section>
    )

} export default Banner;

