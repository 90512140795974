import React, { useState, useEffect } from "react";
import Message  from './index/message';
import FuncCountMon  from './index/FuncCountMon';
import FuncCountYear from './index/FuncCountYear';
import FuncCountNum from './index/FuncCountNum';
import VideoCountNum from './index/VideoCountNum';
import RoleFastSelect from './index/RoleFastSelect';

function Index() {

    return (
            <div className="site-content">
                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i>首頁
                </div>

                <div className="inner">
                    <Message />
                    {/*<RoleFastSelect />*/}

                    <div className="widget-group">
                        <div className="widget-group-left">
                            <div className="widget-box">
                                <div className="widget-box-header" style={{ backgroundColor: '#ff8833' }}>當月單元點閱數統計</div>
                                <FuncCountMon />
                            </div>
                        </div>
                        <div className="widget-group-right">
                            <div className="widget-box" style={{ marginTop: '30px' }}>
                                <div className="widget-box-header" style={{ backgroundColor: '#f55636' }}>年度單元點閱數統計</div>
                                <FuncCountYear />
                            </div>
                        </div>
                    </div>
                    <div className="widget-group">
                        <div className="widget-group-left">
                            <div className="widget-box">
                                <div className="widget-box-header" style={{ backgroundColor: '#1ab1d2' }}>影片點閱數排行</div>
                                <VideoCountNum />
                            </div>
                        </div>
                        <div className="widget-group-right">

                            <div className="widget-box" style={{ marginTop: '30px' }}>
                                <div className="widget-box-header" style={{ backgroundColor: '#03a9f4' }} >影片各類別數量統計</div>
                                <FuncCountNum />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )

} export default Index;

