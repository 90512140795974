import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import printform from "./Print.js"
import $ from "jquery";
import ReactGA from "react-ga4";

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [func, setFunc] = useState({
        funcName: '',
    });

    const [searchData, setsearchData] = useState({
        searchSDate: '',
        searchEDate: '',
        searchText: '',
    });

    const [isSearch, setIsSearch] = useState(false);

    const [search, setsearch] = useState([]);

    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(8) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料

    const [WebImg, setWebImg] = useState([]);

    const funcData = useCallback(async () => {
        const response = await fetch('web/doc/getFunc/' + systemid);
        const data = await response.json();
        setFunc(data);
    }, [systemid]);

    

    useEffect(() => {
        funcData();
        Data(systemid);
        setCurrentPage(0);
        imgurl();
    }, [funcData, systemid ]);

    useEffect(() => {
    }, [searchData]);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);


    //GA
    const path = location.pathname + location.search;
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview", page: path, page_title:""+func.funcName
        });
    }, [path, func]);

    async function Data(systemid) {
        const response = await fetch('web/doc/getCourseList/' + systemid);
        const data = await response.json();

        setList(data);
    }

    function handleSearch(event) {
        event.preventDefault();
        let url = "web/doc/SearchCourseList/" + systemid;
        // Do something with the form data
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(searchData),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.data);
                setList(data.data);
                if (searchData.searchEDate !== '' || searchData.searchSDate !== '' || searchData.searchText !== '') {
                    setIsSearch(true);
                    setsearch(searchData);
                    setCurrentPage(0);
                } else {
                    setIsSearch(false);
                    setsearch([]);
                }
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }
    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })
    };

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    async function imgurl() {
        const response = await fetch('web/setting/GetWebImg');
        const data = await response.text();
        setWebImg(data);
    }

    return (
        <div className="page-main" id="Print_area">
            <div className="page-kv page-kv-course inner">
                <h2 className="page-kv-title"><strong>{func.funcName}</strong></h2>
            </div>

            <div className="crumb">
                <div className="inner crumb-inner">
                    <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >
                    <strong className="current">{func.funcName }</strong>
                </div>
            </div>

            <section className="page">
                <div className="search-list">
                    <div className="search-info inner">
                        <div className="date-wrap-1">
                            <span>發佈日期</span>
                            <label className="hide">起始時間</label>
                            {/*<Datefffuut selected={startDate} onChange={(date) => setStartDate(date)} />*/}
                            <input id="searchSDate" name="searchSDate" type="date" value={searchData.searchSDate} onChange={handleInputChange} max="9999-12-31"  />
                                <span>至</span>
                                <label className="hide">結束時間</label>
                            <input id="searchEDate" name="searchEDate" type="date" value={searchData.searchEDate} onChange={handleInputChange} max="9999-12-31" />
                                </div>
                                <div className="search-cont-1">
                                    <label >關鍵字</label>
                                    <input type="text" name="searchText" className="search" value={searchData.searchText} onChange={handleInputChange} placeholder="請輸入關鍵字" />
                                    <a onClick={handleSearch } className="search-btn" title="查詢">查詢</a>
                                </div>
                        </div>
                    </div>
                    <div className="inner">
                        <div className="page-header">
                        <div className="page-title">{func.funcName}</div>
                            <div className="page-share"><a  title="分享" />
                                <ul className="page-share-list">
                                    <li>
                                        <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                            <img src="assets/images/fb_icon.png" alt="fb icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                            <img src="assets/images/line_icon.png" alt="line icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                            <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                        </a>
                                    </li>
                                    <li>
                                        {printform()}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    {isSearch &&
                        <div>
                            <span>依據查詢條件
                                {search.searchSDate !== '' && <font color='#FF0000'>{"'" + search.searchSDate + "'"}</font>}
                                {search.searchEDate !== '' && <font color='#FF0000'>{"'" + search.searchEDate + "'"}</font>}
                                {search.searchText !== '' && <font color='#FF0000'>{"'" + search.searchText + "'"}</font>}
                                結果共<font color='#FF0000'>{list.length}</font>筆</span>
                        </div>
                    }
                    {currentData.length > 0 ?
                        <><ul className="class-list">
                            {currentData.map((data) => <li key={data.docId}>
                                <a onClick={(event) => handlego(event, "./CourseDetail?systemid=" + func.funcId + "&id=" + data.docId)}>
                                    {data.docTop === 1 && <div className='hastag'><span>置頂</span></div>}
                                    {data.docStatus === 2 && <div className='hastag'><span>Live</span></div>}
                                    {data.imgRandom === 1 ?
                                        <div className="class-pic">
                                            <img src={"./assets/images/class-" + data.docImg + ".jpg"} alt="" />
                                        </div>
                                        :
                                        <div className="class-pic">
                                            <img src={WebImg + func.funcCode + "/" + data.docImg} alt="" />
                                        </div>}
                                    <div className="className-cont">                          
                                        <span className="class-date">{data.docSDate.split("T")[0]}</span>
                                        <strong className="title" dangerouslySetInnerHTML={{ __html: data.docTitle }}></strong>
                                    </div>
                                </a>
                            </li>
                            )}
                        </ul><Pagination count={list.length} page={currentPage } /></>
                        :
                        <div className="paging">
                            <span>暫無資料</span>
                        </div>
                    }

                       {/* <Pagination count={list.length} />*/}

                    </div>
            </section>
        </div>
    )



    function Pagination(props) {

        const num = Math.ceil(props.count / 8);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ( (num - 1 - rightRange)), 0);
        }
        function handleClick(i) {
            setCurrentPage(i);
            window.scrollTo(0, 0);
        };
        function handleChange(event)  {
            setCurrentPage(event.target.value);
            window.scrollTo(0, 0);
        };

        return (
            <div className="paging">
                <a onClick={() => handleClick(0) } className="first"><i className="fas fa-angle-double-left"></i>最前頁</a>
                <a onClick={() => currentPage > 0 && handleClick(currentPage-1)  } className="prev"><i className="fas fa-angle-left"></i>上一頁</a>
                <div className="pcpaging">
                    {[...Array(num)].map((e, i) => {
                        if (num < 5 || (i >= leftRange && i <= rightRange)) {
                            return (
                                <a key={i + 1} onClick={() => handleClick(i)} className={currentPage === i ? "current" : ""}>{i + 1}</a>       
                            );
                        } else if (i === leftRange - 1 || i === rightRange + 1) {
                            return <span key={i + 1}>...</span>;
                        }
                        return null;
                    })}
                </div>
                <div className="rwdpaging">
                    <select name="pagingSelect" id="pagingSelect" onChange={(event) => handleChange(event)} value={currentPage}  >
                        {[...Array(num)].map((e, i) => {
                            return (
                                <option key={i + 1} value={i} >{i+1 }</option>
                            ); 
                        })}
                    </select>
                </div>
                <a onClick={() => currentPage < num - 1 && handleClick(currentPage + 1)} className="next">下一頁<i className="fas fa-angle-right"></i></a>
                <a onClick={() => handleClick(num-1)} className="last">最末頁<i className="fas fa-angle-double-right"></i></a>
            </div>
        );
    }


}
export default From;
