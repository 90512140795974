import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function NavMenu() {

    const [Trader, setTrader] = useState("");
    const [Practitioners, setPractitioners] = useState("");
    const [AdvocateVideo, setAdvocateVideo] = useState("");
    const [EventNews, setEventNews] = useState("");
    const [OtherCourses, setOtherCourses] = useState("");
    const [TaifexLearn, setTaifexLearn] = useState("");



    async function data() {
        const response = await fetch('web/index/getIndexUrl');
        const data = await response.json(); 

        setTrader(data.find(item => item.funcCode === "Trader").funcInterUrl);
        setPractitioners(data.find(item => item.funcCode === "Practitioners").funcInterUrl);
        setAdvocateVideo(data.find(item => item.funcCode === "AdvocateVideo").funcInterUrl);
        setEventNews(data.find(item => item.funcCode === "EventNews").funcInterUrl);
        setOtherCourses(data.find(item => item.funcCode === "OtherCourses").funcInterUrl);
        setTaifexLearn(data.find(item => item.funcCode === "TaifexLearn").funcInterUrl);
    }

    useEffect(() => {
        data();
    }, []);


    return (
        <nav className="main-nav inner">
            <ul className="list">
                <li><a title="期貨交易實務課程">期貨交易實務課程<i className="fas fa-chevron-down"></i></a>
                    <div className="header-sub-nav">
                        <div className="header-sub-nav-inner">
                            <div className="header-sub-nav-title header-sub-nav-title1"><strong>期貨交易實務課程</strong></div>
                            <div className="sub-nav-list">
                                <ul>
                                    <li><Link to={Trader} >一般交易人課程</Link></li>
                                    <li><Link to={Practitioners} >從業人員課程</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li><Link to={AdvocateVideo} >宣導影片</Link></li>
                <li><Link to={EventNews} >活動與新聞</Link></li>
                <li><Link to={OtherCourses} >其他課程</Link></li>
                <li><a href={TaifexLearn} onClick={doLearnCount} title="期貨及選擇權數位學習網" target="_blank" rel="noreferrer">期貨及選擇權數位學習網</a></li>
            </ul>
        </nav>
    )

    function doLearnCount() {
        const response = fetch('web/index/doLearnCount')
    }
} export default NavMenu;