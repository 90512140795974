/* eslint-disable no-undef */
import React, {useEffect, useCallback,useRef } from 'react'; 
import loadGoogleCharts from './googleCharts';

function FuncCountMon() {
    const chartRef = useRef(null);

    const getCourseNum = useCallback(async () => {
        const response = await fetch('admin/index/getFuncCountMon');
        const data = await response.json();

        const chartData = [['label', '點閱率']];
        data.forEach(item => {
            chartData.push([item.label, item.count]);
        });

        const options = {

            chartArea: {
                width: '80%', // 设置图表区域宽度
                height: '80%', // 设置图表区域高度
            },
            legend: { position: 'none' },

        };

        try {

            await loadGoogleCharts();

            // 使用Google Charts库创建图表
            const chart = new google.visualization.ColumnChart(chartRef.current);
            chart.draw(google.visualization.arrayToDataTable(chartData), options);

        } catch (error) {
            console.error('Error loading Google Charts:', error);
        }
    }, []);

    useEffect(() => {
        getCourseNum();
    }, [getCourseNum]);

    return (
        <div ref={chartRef} style={{ 'height':400 } }  className="widget-box-content"></div>
    )

} export default FuncCountMon;