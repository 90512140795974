/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import {  useLocation, useHistory } from 'react-router-dom';


function FromBannerList() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");


    const [bannerlist, setbannerlist] = useState([]);
    const [searchData, setsearchData] = useState({
        searchText: '',
    });

    const [WebImg, setWebImg] = useState([]);

    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = bannerlist.slice(offset, offset + pageSize) // 目前頁面要顯示的資料

    useEffect(() => {
        bannerData(page);
        imgurl();
    }, [page]);

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })
    };

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    async function imgurl() {
        const response = await fetch('web/setting/GetWebImg');
        const data = await response.text();
        setWebImg(data);
    }
     //onClick={(event) => handlego(event, )}
    return (
            <div className="site-content">

                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")} href="/intranet/index">首頁</a>
                    <i className="fas fa-angle-right"></i><strong className="current">Banner管理</strong>
                </div>
                <div className="inner">
                    <div className="unit-title">
                        <h1>Banner管理</h1>
                    </div>
                    <div className="widget-box search">
                        <div className="widget-box-header">查詢</div>
                        <div className="widget-box-content">
                            <table>
                                <tbody>
                                    <tr>
                                        <th className="col-2">關鍵字</th>
                                        <td className="col-10">
                                            <input name="searchText" type="text" id="" value={searchData.searchText} className="col-12" onChange={handleInputChange} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="btn-wrap search">
                        <a onClick={handleSearch} className="search"><i className="fa-solid fa-magnifying-glass"></i>查詢</a>
                    </div>
                    <div className="info-wrap">
                        <p className="search-total">總計<strong>{bannerlist.length}</strong>筆資料</p>
                        <div className="btn">
                            <a onClick={(event) => handlego(event, "/intranet/banner/AddBanner?systemid=3")} href="./intranet/banner/AddBanner?systemid=3">新增</a>
                        </div>
                    </div>
                    <table className="top-th">
                        <thead>
                            <tr>
                                <th className="num">項次</th>
                                <th>名稱</th>
                                <th>圖片</th>
                                <th>連結網址</th>
                                <th>狀態</th>
                                <th style={{ width: '200px' }} >操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((banner, index) =>
                                <tr key={banner.bannerId}>
                                    <td className="text-center">{(currentPage*10)+(index + 1)}</td>
                                    <td>{banner.bannerTitle}</td>
                                    {banner.bannerImg != null ?
                                        <td><img src={WebImg+"banner/" + banner.bannerFile} alt={banner.bannerImg} className="" style={{ width: 100 + "%" , maxWidth: 200 + "px" }}  /></td>
                                    :
                                        <td>無</td>
                                    }
                                    <td>{banner.bannerUrl}</td>
                                    <td>{banner.bannerStatus === 1 ? "使用中" : "停用"}</td>
                                    <td>
                                        <div className="table-btn-wrap">
                                            <a className="table-btn" onClick={() => handlego(event,"./EditBanner?systemid=" + systemid + "&id=" + banner.bannerId)}><i className="fas fa-edit icon"></i>修改</a>
                                            <button className="table-btn" onClick={(event) => handleUp(event, banner.bannerId, banner.bannerSort)}><i className="fas fa-arrow-up icon"></i>上移</button>
                                            <button className="table-btn" onClick={(event) => handleDelete(event, banner.bannerId)}><i className="fas fa-trash-alt icon"></i>刪除</button>
                                            <button className="table-btn" onClick={(event) => handleDown(event, banner.bannerId, banner.bannerSort, bannerlist.length)}><i className="fas fa-arrow-down icon"></i>下移</button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <Pagination count={bannerlist.length } />
                </div>
            </div>
    )

    async function bannerData(page) {

        const response = await fetch('admin/banner/GetBannerList/' + page);
        const data = await response.json();
        setbannerlist(data);
    }


    function handleUp(event, id, sort) {

        let url = "admin/banner/UpBannerSort/" + id+"/"+sort;

        if (sort === 1) {
            alert("已經是最上面了");
        }
        else { 
         // Do something with the form data
         fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    bannerData(page);
                } else {
                    alert("失敗了");
                    history.go(0);
                }
            })
            .catch((error) => {
                window.console.log('Error:' + error);
            });
        }

    }
    function handleDown(event, id, sort, count) {

        let url = "admin/banner/DownBannerSort/" + id + "/" + sort;

        if (sort === count) {
            alert("已經是最下面了");
        }
        else {
            // Do something with the form data
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        bannerData(page);
                    } else {
                        window.history.go(0);
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        }

    }

    function handleDelete(event, id) {


        let url = "admin/banner/DeleteBanner/" + systemid + "/" + id ;

        if (confirm("要將此Banner刪除嗎?")) {
            // Do something with the form data
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert("刪除成功");
                        bannerData(page);
                    } else {

                        bannerData(page);
                        alert(data.message);
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        }
    }

    function handleSearch(event) {

        let url = "admin/banner/Search";
        // Do something with the form data
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(searchData),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                setbannerlist(data);
                setCurrentPage(0);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        function handleClick(i) {
            setCurrentPage(i);
            window.scrollTo(0, 0);
        };

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a key={i + 1} onClick={() => handleClick(i)} className={currentPage === i ? "active" : ""}>{i + 1}</a>
                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }

}
export default FromBannerList;

