/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

function NavMenu() {

    const history = useHistory();

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    } 

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        func();
    }, []);

    function func() {
         fetch('admin/home/getfunc')
            .then((response)=>response.json())
            .then((data) =>
                setMenuItems(data)
            )
            .catch();
    }

    //onClick={(event) => handlego(event, )}

    const buildMenuTree = (Item) => {
        const children = menuItems.filter((item) => item.funcParent === Item.funcId);
        if (!children.length>0) {
            return (
                <li key={Item.funcId}>
                    <a onClick={(event) => handlego(event, Item.funcIntraUrl)}  className="clickable">
                        <i className="fas fa-cog fa-fw icon"></i>{Item.funcName}
                    </a>
                </li>);
        }
        else
        { 
            return (
                <li key={Item.funcId}>
                    <a  className="clickable" >
                        <i className="fas fa-cog fa-fw icon"></i>{Item.funcName}<i className="fa-solid fa-chevron-right"></i>
                    </a>
                    <ul className="sub-menu lev2" >
                        {
                            children.map((child) => (
                             buildMenuTree2(child)
                        ))
                        }
                    </ul>
                </li>
            );
        }
    };

    const buildMenuTree2 = (Item) =>
    {
        const children = menuItems.filter((item) => item.funcParent === Item.funcId);

        if (!children.length > 0) {
            return (
                <li key={Item.funcId}>
                    <a onClick={(event) => handlego(event, Item.funcIntraUrl)}><span className="dot"></span>{Item.funcName}</a>
                </li>);
        } 
        else {
            return (
                <li key={Item.funcId}>
                    <a className="clickable">
                        <span className="dot" />{Item.funcName}<i className="fa-solid fa-chevron-right"></i>
                    </a>
                    <ul className="sub-menu lev3" >
                        {
                            children.map((child) => (
                                    buildMenuTree3(child)
                            ))
                        }
                    </ul>
                </li>
            );
        }
        
    }

    const buildMenuTree3 = (Item) => {
        const children = menuItems.filter((item) => item.funcParent === Item.funcId);

        if (!children.length > 0) {
            return (
                <li key={Item.funcId}>
                    <a onClick={(event) => handlego(event, Item.funcIntraUrl)} key={Item.funcId}><span className="dot"></span>{Item.funcName}</a>
                </li>);
        }
        else {
            return (
                <li key={Item.funcId}>
                    <a className="clickable">
                        <span className="dot" />{Item.funcName}
                    </a>
                    <ul className="sub-menu lev4" >
                        {
                            children.map((child) => (
                                <li key={child.funcId}>
                                    <a onClick={(event) => handlego(event, child.funcIntraUrl)}  key={child.funcId}><span className="dot"></span>{child.funcName}</a>
                                </li>
                            ))
                        }
                    </ul>
                </li>
            );
        }
    }
    return (
        <>
            <div id="side-scroll" className="site-side">
                <div className="site-side-inner" style={{ overflow: 'auto' }}>
                    <ul className="main-menu lev1">
                        {menuItems.map((Item)=>
                            Item.funcParent === 0 &&
                             buildMenuTree(Item) 
                        )}
                    </ul>
                </div>
            </div>
            <a className="side-menu__switch side-menu__close" style={{left: 240+"px"}}></a>
            
        </>
    )

};

export default NavMenu;
