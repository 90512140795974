/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import printform from "./Print.js"
import $ from "jquery";
import ReactGA from "react-ga4";

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const seach = params.get("search");

    const history = useHistory();

    const [list, setList] = useState([]);



    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(8) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料



    useEffect(() => {
        Data(seach);
    }, [seach]);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);

    //GA
    const path = location.pathname + location.search;
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: path, page_title: "搜尋" });
    }, [path]);

    return (
        <div className="page-main" id="Print_area">
            <div className="page-kv page-kv-course inner">
                <h2 className="page-kv-title"><strong>搜尋結果</strong></h2>
            </div>

            <div className="crumb">
                <div className="inner crumb-inner">
                    <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >
                    <strong className="current">搜尋結果</strong>
                </div>
            </div>

            <section className="page">
                <div className="inner">
                    <div className="page-header">
                        <div className="page-title">搜尋結果</div>
                        <div className="page-share"><a title="分享" />
                            <ul className="page-share-list">
                                <li>
                                    <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                        <img src="assets/images/fb_icon.png" alt="fb icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                        <img src="assets/images/line_icon.png" alt="line icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                        <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => printform('Print_area', 1280)} title="列印">
                                        <img src="assets/images/print_icon.png" alt="列印 icon" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {currentData.length > 0 ?
                        <ul className="class-list">
                            {currentData.map((data) =>      
                                <li key={data.docId}>
                                        {
                                        data.funcName === "交易人員課程" || data.funcName === "從業人員課程"
                                        ?
                                            <a href={"/Trader/CourseDetail?systemid=" + data.funcId + "&id=" + data.docId} >
                                                {data.docTop === 1 &&
                                                    <div className="hastag">
                                                        <span>置頂</span>
                                                    </div>
                                                }
                                                {data.imgRandom === 1 ?
                                                    <div className="class-pic">
                                                        <img src={"./assets/images/class-" + data.docImg + ".jpg"} alt="" />
                                                    </div>
                                                    :
                                                    <div className="class-pic">
                                                        <img src={"assets/upload/" + data.funcCode + "/" + data.docImg} />
                                                    </div>
                                                }
                                                <div className="className-cont">
                                                    <span className="class-date">{data.docSDate.split("T")[0]}</span>
                                                    <strong className="title">{data.docTitle}</strong>
                                                </div>
                                            </a>
                                            :
                                            <a href={"/CourseDetail?systemid=" + data.funcId + "&id=" + data.docId} >
                                            {data.docTop === 1 &&
                                                <div className="hastag">
                                                    <span>置頂</span>
                                                </div>
                                            }
                                            {data.imgRandom === 1 ?
                                                <div className="class-pic">
                                                    <img src={"assets/images/class-" + (Math.floor(Math.random() * 8) + 1) + ".jpg"} />
                                                </div>
                                                :
                                                <div className="class-pic">
                                                    <img src={"assets/upload/" + data.funcCode + "/" + data.docImg} />
                                                </div>
                                            }
                                            <div className="className-cont">
                                                <span className="class-date">{data.docSDate.split("T")[0]}</span>
                                                    <strong className="title">{data.docTitle}</strong>
                                            </div>
                                            </a>
                                        }
                                </li>
                            )}
                        </ul>
                        :
                        <div className="paging">
                            <span>暫無資料</span>
                        </div>
                    }
                    <Pagination count={list.length} />
                </div>
            </section>
        </div>
    )

    async function Data(search) {
        const response = await fetch('web/doc/SearchAllList/' + search);
        const data = await response.json();

        setList(data);
    }

    function Pagination(props) {

        const num = Math.ceil(props.count / 8);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }
        function handleClick(i) {
            setCurrentPage(i);
            window.scrollTo(0, 0);
        };
        function handleChange(event) {
            setCurrentPage(event.target.value);
            window.scrollTo(0, 0);
        };

        return (
            <div className="paging">
                <a onClick={() => handleClick(0)} className="first"><i className="fas fa-angle-double-left"></i>最前頁</a>
                <a onClick={() => currentPage > 0 && handleClick(currentPage - 1)} className="prev"><i className="fas fa-angle-left"></i>上一頁</a>
                <div className="pcpaging">
                    {[...Array(num)].map((e, i) => {
                        if (num < 5 || (i >= leftRange && i <= rightRange)) {
                            return (
                                <a key={i + 1} onClick={() => handleClick(i)} className={currentPage === i ? "current" : ""}>{i + 1}</a>
                            );
                        } else if (i === leftRange - 1 || i === rightRange + 1) {
                            return <span key={i + 1}>...</span>;
                        }
                        return null;
                    })}
                </div>
                <div className="rwdpaging">
                    <select name="pagingSelect" id="pagingSelect" onChange={(event) => handleChange(event)} value={currentPage}  >
                        {[...Array(num)].map((e, i) => {
                            return (
                                <option key={i + 1} value={i} >{i + 1}</option>
                            );
                        })}
                    </select>
                </div>
                <a onClick={() => currentPage < num - 1 && handleClick(currentPage + 1)} className="next">下一頁<i className="fas fa-angle-right"></i></a>
                <a onClick={() => handleClick(num - 1)} className="last">最末頁<i className="fas fa-angle-double-right"></i></a>
            </div>
        );
    }


}
export default From;