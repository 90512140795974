import $ from "jquery"
import React from 'react';


function open_hidden_div(){
	$("#print_div").show();
}
		
var printWindow;
		
//function printform(Orj, contentWidth) {
//          //var printContent = document.getElementById('page');
			
//			//by david
//			//1002 or 680
//			//if(contentWidth=="undefined"){
//			if (typeof contentWidth == "undefined"){
//			    contentWidth = "680";
//			}
			
//			var printContent = document.getElementById(Orj);
//            var windowUrl = 'about:blank';
//            var uniqueName = new Date();
//            var windowName = 'Print' + uniqueName.getTime();
//			var printContentObj = $('#'+Orj).clone();			
			
//			var printWindow = window.open(windowUrl, windowName);
			
//			var html_head = document.getElementById('html_head');
			
//			printWindow.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">');
//			printWindow.document.write('<html xmlns="http://www.w3.org/1999/xhtml" lang="zh-tw">');
//			printWindow.document.write('<head>');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/normalize.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/animate.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/all.css" />');
//			//printWindow.document.write('<link rel="stylesheet" href="/assets/css/slidebars.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/slick.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/basic.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/page.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/index.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/responsive.css" />');
//			printWindow.document.write('<link rel="stylesheet" href="/assets/css/print.css" />');
			 
//			//alert(html_head);
//			if(html_head != null){
//				var head_str = html_head.innerHTML ; 
//				printWindow.document.write(head_str);
//			}
						
//			printWindow.document.write('</head>');
//			printWindow.document.write('<body>');
//			if(printContent != null){
				
				
//				//printWindow.document.write('<center>')
				
//				//margin:0 auto; 置中
//				//width:undefinedpx; margin:0 auto; 無法置中
//				//width:680px; margin:0 auto;		

//				printWindow.document.write("<div style='width:"+contentWidth+"px; margin:45px auto 0 auto;' data-type='content'>");
//				printWindow.document.write(printContentObj.html());
//				printWindow.document.write('</div>');
				
//                printWindow.document.write('<div class="PrintInput" id="print_div">');
//				printWindow.document.write('<a href="javascript:window.print();open_hidden_div();" class="print" id="print_block">列印</a>');
//				printWindow.document.write('&nbsp;&nbsp;');
//				printWindow.document.write('<a href="javascript:window.close()" class="print">關閉</a>');
//				printWindow.document.write('</div>');			
				
				
//				//printWindow.document.write('</center>')
				
//			}
//			printWindow.document.write('</body>');
//			printWindow.document.write('</html>');
			
			
			
			
//            printWindow.document.close();
			
			
			
//			//避免JQ載入不成功，重新reload 即可解決問題 by jeff
//			//Chrome 會有問題 by david, 排除 Chrome
//			//printWindow.location.reload();			
//			//alert(navigator.userAgent)
//			var isChrome = navigator.userAgent.search("Chrome") > -1;  
//			//alert(isChrome);
//			if(isChrome){
//			}else{
//				printWindow.location.reload();
//			}
			
//			setTimeout(function(){
				
//				if($('div[data-type="content"]', printWindow.document).html()!=''){
//					//alert(navigator.userAgent);
//					if(navigator.userAgent.indexOf(".NET")>=0){
//						//alert('do win++'+navigator.userAgent);
//						$('div[data-type="content"]', printWindow.document).html('');
//						$('div[data-type="content"]', printWindow.document).html(printContentObj.html());
//						//alert($('#'+Orj).find('#Country1 option:selected').val());
//					}
//					else{
//						//alert('do no win++'+navigator.userAgent);
//						$('div[data-type="content"]', printWindow.document).html('');
//						$('div[data-type="content"]', printWindow.document).append(printContentObj);
//					}
//					$('div[data-type="content"]', printWindow.document).find('#Country1').val($('#'+Orj).find('#Country1 option:selected').val());

//					$('div[data-type="content"]', printWindow.document).find('#class_name').val($('#'+Orj).find('#class_name').find('option:selected').val());
//					$('div[data-type="content"]', printWindow.document).find('#class_name_c').val($('#'+Orj).find('#class_name_c').find('option:selected').val());
//					$('div[data-type="content"]', printWindow.document).find('#area').val($('#'+Orj).find('#area').find('option:selected').val());
					
//					$('div[data-type="content"]', printWindow.document).find(".faq-content").show();
//					$('div[data-type="content"]', printWindow.document).find(".page-sharing").remove();

//				}
//			},400);
			
//            printWindow.focus();

//			// 不預設開印表機 by david
//            //printWindow.print();
			
//            //printWindow.close();
//} export default printform;

function printform() {

	const handleClick = (Orj, contentWidth) => {
		// 開啟新視窗
		var printContent = document.getElementById(Orj);
		var windowUrl = 'about:blank';
		var uniqueName = new Date();
		var windowName = 'Print' + uniqueName.getTime();
		var printContentObj = $('#' + Orj).clone();		
		const newWindow = window.open('', '_blank');
		// 複製當前畫面的內容到新視窗中
		/*		newWindow.document.write(window.document.documentElement.innerHTML);*/

		const html = window.document.documentElement.innerHTML;

		newWindow.document.write(document.head.innerHTML);

		newWindow.document.write('<link rel="stylesheet" href="/assets/css/print.css" />');
		newWindow.document.write("<div style='width:" + contentWidth + "px; margin:45px auto 0 auto;' data-type='content'>");
		newWindow.document.write(printContentObj.html());
		newWindow.document.write('</div>');
		newWindow.document.write('<div class="PrintInput" id="print_div">');
		newWindow.document.write('<a href="javascript:window.print();open_hidden_div();" class="print" id="print_block">列印</a>');
		newWindow.document.write('&nbsp;&nbsp;');
		newWindow.document.write('<a href="javascript:window.close()" class="print">關閉</a>');
		newWindow.document.write('</div>');	
		newWindow.document.close();
	
	};

	return (
		<a onClick={() => handleClick('Print_area', 1280)} title="列印">
			<img src="assets/images/print_icon.png" alt="列印 icon" />
		</a>
	);

} export default printform;