import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ReactGA from "react-ga4";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
GA();

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
    <App />
    </BrowserRouter>,
  rootElement);

function GA() { 
fetch('web/setting/GetGA')
    .then(response => response.text())
    .then(data => {
        ReactGA.initialize(data)
        //console.log(data);
    })
    .catch(error => {
        console.error('Error:', error);
    });
}