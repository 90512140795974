/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ImgSlider from './ImgSlider';

function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const systemid = params.get("systemid");

    const uu = uuidv4();

    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [file, setFile] = useState([]);

    const [uploaded, setUploaded] = useState([]);

    const [img, setImg] = useState();

    const [func, setFunc] = useState([]);

    const [guid] = useState(uu);

    const [formData, setFormData] = useState({
        docTitle: '',
        docContent: '',
        docSDate: formattedDate,
        docEDate: '',
        docENever: 1,
        docTop: 0 ,
        docVideoUrl: '',
        docImgSize: 0,
        docImgRandom: 1,
        docStatus: 0,
        docSort: '',
        docRandomImg: Math.floor(Math.random() * 16) + 1,
        docWarn: (systemid === "12" || systemid === "13") ? 1 : 0,
    });

    const funcData = useCallback(async (systemid) => {
        const response = await fetch('admin/doc/getFunc/' + systemid);
        const data = await response.json();
        setFunc(data);
    }, []);

    useEffect(() => {
        funcData(systemid);
    }, [funcData, systemid]);


    const handleInputChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: event.target.value })
        //console.log(file);
    };

    const handleRadioChange = (event) => {
        let changeName = event.target.name
        setFormData({ ...formData, [changeName]: parseInt(event.target.value) })
    };

    const handleCheckChange = (event) => {
        let changeName = event.target.name
        if (formData.docENever === 0) {
            setFormData({ ...formData, [changeName]: 1 })
        } else {
            setFormData({ ...formData, [changeName]: 0 })
        }
    };

    const handleUploadChange = (event) => {
        setImg(event.target.files[0])
        if (event.target.files[0]!=null) { 
            setFormData({ ...formData, "docImgSize": event.target.files[0].size })
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0])
        
    };

    const handleFileSubmit = (event) => {
        const upload = new FormData();
        upload.append('file', file);
        if (true) {
            fetch('/admin/doc/UploadAtt/' + systemid + '/' + guid , {
                method: 'POST',
                body: upload,

            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        const tem = uploaded;
                        tem.push(data.docAtt)
                        setUploaded(tem);
                        setFile(null);
                        document.getElementById("docAtt").value = ""
                    }
                    else {
                        alert("新增檔案失敗" + data.message);
                        setFile(null);
                        document.getElementById("docAtt").value = ""
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            //console.log("tete");
        }
    };

    function handleFileDelete(event, id, index) {
        event.preventDefault();
        const upload = new FormData();
        upload.append('file', file);
        if (true) {
            fetch('/admin/doc/DeleteAtt/' + systemid + '/' + id, {
                method: 'POST',
                body: upload,

            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert(data.message);
                        const tem = uploaded;
                        tem.splice(index, 1);
                        setUploaded([]);
                        setUploaded(tem);
                    }
                    else {
                        alert("刪除檔案失敗" + data.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const history = useHistory();

    const handleSubmit = (event) => {
        event.preventDefault();
        const upload = new FormData();
        upload.append('docTitle', formData.docTitle);
        upload.append('docContent', formData.docContent);
        upload.append('docSDate', formData.docSDate);
        upload.append('docEDate', formData.docEDate);
        upload.append('docENever', formData.docENever);
        upload.append('docTop', formData.docTop);
        upload.append('docVideoUrl', formData.docVideoUrl);
        upload.append('ImgRandom', formData.docImgRandom);
        upload.append('docStatus', formData.docStatus);
        upload.append('docSort', formData.docSort);
        upload.append('docRandomImg', formData.docRandomImg);
        upload.append('docWarn', formData.docWarn);   
        upload.append('file', img);

        if (formData.docImgSize <= (1024 * 1024 * 5) || img.length<0) {
            fetch('/admin/doc/AddCourse/' + systemid + '/' + guid , {
                method: 'POST',
                body:upload,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success ) {
                        alert("新增成功");
                        history.push('/intranet/video/CourseList?systemid=' + systemid +'&page=1');
                    }
                    else {
                        alert("新增失敗;" + data.message);
                        history.push('/intranet/video/CourseList?systemid=' + systemid + '&page=1');
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            alert("圖片大小不可超過5MB");
        }
    }

    function handleFileDownload(event, id, name) {
        event.preventDefault();

        fetch('/admin/doc/DownloadAtt/' + systemid + '/' + id,)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = name ;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error(error);
            });

    }

    const handlecancel = (event) => {
        event.preventDefault();
        history.push('/intranet/video/CourseList?systemid=' + systemid + '&page=1');
    }

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
     //onClick={(event) => handlego(event,"/intranet/index")}

    return (
        <>
                <div className="site-content">

                    <div className="breadcrumb">
                        <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")} href="/intranet/index">首頁</a>
                        <i className="fas fa-angle-right"></i><a >網站內容管理</a>
                        <i className="fas fa-angle-right"></i><a >主選單管理</a>
                        <i className="fas fa-angle-right"></i><strong className="current">{func.funcName}</strong>
                    </div>

                    <div className="inner">
                        <div className="unit-title">
                            <h1>{func.funcName}</h1>
                        </div>
                        <form onSubmit={handleSubmit} encType="multipart/form-data" method="POST" >
                        <div className=" widget-box">
                            <div className="widget-box-header">新增</div>
                            <div className="widget-box-content">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th className="col-2">影片名稱</th>
                                            <td className="col-10">
                                                <input name="docTitle" type="text" className="col-12" value={formData.docTitle} onChange={handleInputChange} maxLength="50" />
                                                <span className="red">長度50中英文字</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>節目說明</th>
                                            <td>
                                                <textarea rows="3" name="docContent" className="col-12" value={formData.docContent} onChange={handleInputChange}></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>上架日期</th>
                                            <td>
                                                <div className="date-wrap">
                                                    <input type="date" name="docSDate" value={formData.docSDate} onChange={handleInputChange} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>下架日期</th>
                                            <td>
                                                <div className="date-wrap"><input type="date" name="docEDate" value={formData.docEDate} onChange={handleInputChange} /></div>
                                                <div style={{ marginTop: 8 + "px" }}><input type="checkbox" name="docENever" onChange={handleCheckChange} checked={formData.docENever === 1} />永遠上架</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Youtube影片網址</th>
                                            <td>
                                                <input type="text" className="col-12" name="docVideoUrl" value={formData.docVideoUrl} onChange={handleInputChange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>封面圖</th>
                                            <td>
                                                <div className="form-inline">
                                                    <div><input type="radio" name="docImgRandom" value="1" onChange={handleRadioChange} checked={formData.docImgRandom === 1} />內建圖片</div>
                                                    <div><input type="radio" name="docImgRandom" value="0" onChange={handleRadioChange} checked={formData.docImgRandom === 0} />上傳圖片</div>
                                                </div>
                                                {formData.docImgRandom === 1 ? <div >
                                                                                    <select name="docRandomImg" value={formData.docRandomImg} onChange={handleInputChange}>
                                                                                        <option value="1">1</option>
                                                                                        <option value="2">2</option>
                                                                                        <option value="3">3</option>
                                                                                        <option value="4">4</option>
                                                                                        <option value="5">5</option>
                                                                                        <option value="6">6</option>
                                                                                        <option value="7">7</option>
                                                                                        <option value="8">8</option>
                                                                                        <option value="9">9</option>
                                                                                        <option value="10">10</option>
                                                                                        <option value="11">11</option>
                                                                                        <option value="12">12</option>
                                                                                        <option value="13">13</option>
                                                                                        <option value="14">14</option>
                                                                                        <option value="15">15</option>
                                                                                        <option value="16">16</option>
                                                                                    </select>
                                                                                </div>
                                                        : <div style={{ 'display': 'none' }}><input name="docRandomImg" type="text" value={formData.docRandomImg} onChange={handleInputChange} maxLength="3" /></div>}
                                                {formData.docImgRandom === 1 ? <div style={{ 'display': 'none' }}> <input name="docImg" type="file" onChange={handleUploadChange} disabled /></div> : <div><input type="file" onChange={handleUploadChange} accept="image/png, image/jpeg" /></div>}
                                                {formData.docImgRandom === 1 ?                                                   
                                                        <ImgSlider />
                                                    : <span className="red">(建議圖片解析度為280px ＊190px，允許上傳jpg/png檔案，大小5MB)</span>}                                                      
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>附件上傳</th>
                                            <td>
                                                <input type="file" id="docAtt" name="docAtt" onChange={handleFileChange} accept=".pdf"/>
                                                <a onClick={handleFileSubmit} className="table-inline-btn"><i className="fas fa-folder-open icon"></i>上傳</a>
                                                {uploaded.map((upload, index) =>
                                                    <ul className="file-list" key={index}>
                                                        <li>
                                                            <a onClick={(event) => handleFileDownload(event, upload.docAttId, upload.docAttContentReal)} ><i className="fas fa-file-pdf fa-fw icon"></i>{upload.docAttContentReal}</a>
                                                            <a onClick={(event) => handleFileDelete(event,upload.docAttId, index) } className="delete-btn">刪除</a>
                                                        </li>
                                                    </ul>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>狀態</th>
                                            <td>
                                                <select name="docStatus" value={formData.docStatus} onChange={handleInputChange}>
                                                    <option value="0">不顯示</option>
                                                    <option value="1">顯示</option>
                                                    <option value="2">顯示Live</option>
                                                    <option value="3">非公開</option>
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>警語顯示</th>
                                            <td>
                                                <div className="form-inline"  >
                                                    <div><input type="radio" name="docWarn" onChange={handleRadioChange} value="1" checked={formData.docWarn === 1} />是</div>
                                                    <div><input type="radio" name="docWarn" onChange={handleRadioChange} value="0" checked={formData.docWarn === 0} />否</div>
                                                </div>
                                            </td>
                                            </tr>
                                        <tr>
                                            <th>是否置頂</th>
                                            <td>
                                                <div className="form-inline"  >
                                                    <div><input type="radio" name="docTop" onChange={handleRadioChange} value="1" checked={formData.docTop === 1} />是</div>
                                                    <div><input type="radio" name="docTop" onChange={handleRadioChange} value="0" checked={formData.docTop === 0} />否</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>排序</th>
                                            <td>
                                                <input type="text" name="docSort" value={formData.docSort} onChange={handleInputChange} />
                                                <span className="red">此排序為置頂後的排序，1是最前面</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="btn-wrap bottom">
                                <a onClick={handleSubmit } className="certain">儲存</a>
                                <a onClick={handlecancel } className="certain">返回</a>
                            </div>
                        </div>
                        </form>
                    </div>  
                </div>
        </>
    )


}
export default From;