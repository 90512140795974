import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Header from './Header';
import Index   from './Index';

import GroupList from './admin/system/GroupList';
import AddGroup from './admin/system/AddGroup';
import EditGroup from './admin/system/EditGroup';
import EditGroupFunc from './admin/system/EditGroupFunc';
import EditGroupUser from './admin/system/EditGroupUser';

import RoleList  from './admin/system/RoleList';
import AddRole from './admin/system/AddRole';
import EditRole from './admin/system/EditRole';

import UserLog from './admin/system/UserLog';
import UserGroup from './admin/system/UserGroup';
import PersonRole from './admin/system/PersonRole';
import PersonRoleFunc from './admin/system/PersonRoleFunc';

import BannerList from './banner/BannerList';
import AddBanner from './banner/AddBanner';
import EditBanner from './banner/EditBanner';

import AddCourse from './video/AddCourse';
import CourseList from './video/CourseList';
import EditCourse from './video/EditCourse';


import EditIntellectual from './footer/EditIntellectual';
import EditContactInfo from './footer/EditContactInfo';
import EditWarn from './footer/EditWarn';

import VideoCountList from './count/VideoCountList';
import FuncCount from './count/FuncCount';

import NewsList from './news/NewsList';

import  FooterScript  from './FooterScript';
import { Footer } from './Footer';



import  NavMenu  from './NavMenu';


function Home() {

    useEffect(() => {
        //setTimeout(() => {
        //    setPageLoaded(true);
        //}, 1000);
        setTimeout(() => {
            document.querySelector('.wrapper').style.opacity = 1;
        }, 500); // 1秒後顯現元素
    }, []);

    const ScrollToTop = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    };
        return (
            <div className="wrapper" style={{ opacity: "0", transition: "opacity 1s " }}>
                    <ScrollToTop />
                    <Header />
                    <NavMenu />
                <div id="site-main" className="page">
                <Switch >
                        <Route path='/intranet/Index' component={Index} /> 
                        <Route path='/intranet/admin/system/GroupList' component={GroupList} />
                        <Route path='/intranet/admin/system/AddGroup' component={AddGroup} />
                        <Route path='/intranet/admin/system/EditGroup' component={EditGroup} />
                        <Route path='/intranet/admin/system/EditGroupFunc' component={EditGroupFunc} />
                        <Route path='/intranet/admin/system/EditGroupUser' component={EditGroupUser} />

                        <Route path='/intranet/admin/system/RoleList' component={RoleList} />
                        <Route path='/intranet/admin/system/AddRole' component={AddRole} />
                        <Route path='/intranet/admin/system/EditRole' component={EditRole} /> 

                        <Route path='/intranet/admin/system/UserLog' component={UserLog} /> 
                        <Route path='/intranet/admin/system/UserGroup' component={UserGroup} /> 
                        <Route path='/intranet/admin/system/PersonRole' component={PersonRole} /> 
                        <Route path='/intranet/admin/system/PersonRoleFunc' component={PersonRoleFunc} /> 

                        <Route path='/intranet/banner/BannerList' component={BannerList} /> 
                        <Route path='/intranet/banner/AddBanner' component={AddBanner} /> 
                        <Route path='/intranet/banner/EditBanner' component={EditBanner} /> 

                        <Route path='/intranet/video/AddCourse' component={AddCourse} /> 
                        <Route path='/intranet/video/CourseList' component={CourseList} /> 
                        <Route path='/intranet/video/EditCourse' component={EditCourse} /> 

                        <Route path='/intranet/footer/EditIntellectual' component={EditIntellectual} /> 
                        <Route path='/intranet/footer/EditContactInfo' component={EditContactInfo} /> 
                        <Route path='/intranet/footer/EditWarn' component={EditWarn} /> 

                        <Route path='/intranet/count/VideoCountList' component={VideoCountList} /> 
                        <Route path='/intranet/count/FuncCount' component={FuncCount} /> 

                        <Route path='/intranet/news/NewsList' component={NewsList} /> 

                        <Redirect to="/intranet/Index" />
                    </Switch >
                </div>
                    <Footer />
                    <FooterScript />
            </div>
        )
}
export default Home;
                //<Header />
                //<NavMenu />
                //<Footer />
                //<FooterScript />