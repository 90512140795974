/* eslint-disable no-undef */
import React, {useEffect, useCallback,useRef } from 'react'; 
import loadGoogleCharts from './googleCharts';


function FuncCountMon() {
    const chartRef = useRef(null);

    const getCourseNum = useCallback(async () => {
        const response = await fetch('admin/index/getVideoCountNum');
        const data = await response.json();

        try {
            await loadGoogleCharts();

        const chartData = [['label', '點閱率']];
        data.forEach(item => {
            chartData.push([item.label, item.count]);
        });

            var table = google.visualization.arrayToDataTable(chartData);

            var view = new google.visualization.DataView(table);

        view.setColumns([0, 1,
            {
                calc: "stringify",
                sourceColumn: 1,
                type: "string",
                role: "annotation"
            }]);
            

        const options = {

            chartArea: {
                width: '65%', // 设置图表区域宽度
                height: '90%', // 设置图表区域高度
            },
            legend: { position: 'none' },
            bar: { groupWidth: "50%" }
        };

        

            

            // 使用Google Charts库创建图表
            const chart = new google.visualization.BarChart(chartRef.current);
            chart.draw(view, options);

        } catch (error) {
            console.error('Error loading Google Charts:', error);
        }
    }, []);

    useEffect(() => {
        getCourseNum();
    }, [getCourseNum]);

    return (
        <div ref={chartRef} style={{ 'height':400 } }  className="widget-box-content"></div>
    )

} export default FuncCountMon;