import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Banner() {

    const [demoImg] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16']);


    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        prevArrow: <img src='assets/images/kv-arr1.png' alt="Previous" />,
        nextArrow: <img src='assets/images/kv-arr2.png' alt="Next" />,
    };

    return (
        <div className='slider-out'>
                <Slider {...settings} className='slider-inner'>
                    {demoImg.map((item, index) =>
                        <img key={index} src={"./assets/images/class-" + item + ".jpg"} alt={"class-" + item} title={item}  />
                    )}
            </Slider>
        </div>
    )

} export default Banner;

