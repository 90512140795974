/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';


function From() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [list, setList] = useState([]);

    const [func, setFunc] = useState([]);


    const [searchData, setsearchData] = useState({
        searchText: '',
        searchSDate: '',
        searchEDate: '',
        searchCategory: 99,
    });


    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = list.slice(offset, offset + pageSize) // 目前頁面要顯示的資料


    useEffect(() => {
        Data();
    }, []);

    const handleInputChange = (event) => {
        let changeName = event.target.name
        setsearchData({ ...searchData, [changeName]: event.target.value })
    };
    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
    async function Data() {

        const response = await fetch('admin/count/getFuncCount');
        const data = await response.json();
        setList(data);

    }

    function handleSearch(event) {
        event.preventDefault();
        let url = "admin/count/getFuncSearch/" + systemid;
        let body = {};
        var flag = false;
        if (searchData.searchSDate && searchData.searchEDate) {
            body.searchSDate = searchData.searchSDate;
            body.searchEDate = searchData.searchEDate;
            flag = true;
        }
        if (flag) {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    setList(data);
                    setCurrentPage(0);
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        } else {
            alert("請輸入搜尋日期")
        }
    }

    function handleExcel(event) {

        const now = new Date();
        const year = now.getFullYear().toString();
        const month = String(now.getMonth() + 1).padStart(2, '0').toString();
        const day = String(now.getDate()).padStart(2, '0').toString();
        const today = year + month + day;

        event.preventDefault();
        let url = "admin/count/FuncCountExcel/" + systemid;
        let body = {
            searchText: searchData.searchText,
            searchCategory: searchData.searchCategory
        };

        if (searchData.searchSDate) {
            body.searchSDate = searchData.searchSDate;
        }

        if (searchData.searchEDate) {
            body.searchEDate = searchData.searchEDate;
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = today + "期交所單元項目點閱率列印";
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        function handleClick(i) {
            setCurrentPage(i);
            window.scrollTo(0, 0);
        };

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a key={i + 1} onClick={() => handleClick(i)} className={currentPage === i ? "active" : ""}>{i + 1}</a>
                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }

    return (
            <div className="site-content">

                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index" >首頁</a>
                    <i className="fas fa-angle-right"></i><a >統計報表</a>
                    <i className="fas fa-angle-right"></i><strong className="current">單元項目進行點閱數累計</strong>
                </div>

                <div className="inner">
                    <div className="unit-title">
                        <h1>單元項目進行點閱數累計</h1>
                    </div>
                    <div className="widget-box search">
                        <div className="widget-box-header">查詢</div>
                        <div className="widget-box-content">
                            <table>
                                <tbody>
                                    <tr>
                                        <th className="col-2">查詢區間</th>
                                        <td className="col-10">
                                            <div className="date-wrap">
                                                <input type="date" name="searchSDate" className="hasDatepicker" value={searchData.searchSDate} onChange={handleInputChange} />
                                                <span>~</span>
                                                <input type="date" name="searchEDate" className="hasDatepicker" value={searchData.searchEDate} onChange={handleInputChange} />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    <div className="btn-wrap search">
                        <a onClick={handleSearch } className="search"><i className="fas fa-search"></i>查詢</a>
                        </div>
                        <div className="info-wrap">
                            <p className="search-total">總計<strong>{list.length}</strong>筆資料</p>
                            <div className="btn">
                            <a onClick={handleExcel}>匯出</a>
                            </div>
                        </div>
                        <div className="widget-box">
                            <div className="widget-box-content">
                                <table className="top-th">
                                    <tbody>
                                        <tr>
                                            <th className="num">項次</th>
                                            <th>單元</th>
                                            <th>瀏覽人次</th>
                                        </tr>
                                    {currentData.map((data,index)=>
                                        <tr key={data.docTitle }>
                                            <td>{(currentPage * 10) + (index + 1)}</td>
                                            <td>{data.funcName }</td>
                                            <td>{data.docVcounter }</td>
                                        </tr>
                                    ) }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination count={list.length} />
                    </div>
                </div>
    )

}
export default From;
