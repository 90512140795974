import React, { useEffect  } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import  Header  from './Header';
import  Footer  from './Footer';
import  NavMenuRWD  from './NavMenuRWD';
import { FooterScript } from './FooterScript';

import index from './index';
import CourseList from './CourseList'; 
import SearchCourseList from './SearchCourseList'; 
import SearchAllList from './SearchAllList'; 
import CourseDetail from './CourseDetail'; 

import Intellectual from './Intellectual'; 
import Sitmap from './Sitmap'; 

import CompanyProfile from './CompanyProfile'; 
import Post from './Post'; 
import News from './News'; 

function Home() {

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.wrapper').style.opacity = 1;
        }, 1000); // 1秒後顯現元素
    }, []);

    const ScrollToTop = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    };


    return (
        <>
            <div className="wrapper" style={{ opacity: "0", transition: "opacity 0.5s " }}>
                <ScrollToTop />
                <Header />
                <Switch  >
                    <Route path='/index' component={index} />
                    <Route path='/CourseList' component={CourseList} />
                    <Route path='/SearchCourseList' component={SearchCourseList} />
                    <Route path='/SearchAllList' component={SearchAllList} />
                    <Route path='/CourseDetail' component={CourseDetail} />
                    <Route path='/Intellectual' component={Intellectual} />
                    <Route path='/Sitmap' component={Sitmap} />
                    <Route path='/CompanyProfile' component={CompanyProfile} />
                    <Route path='/Post' component={Post} />
                    <Route path='/News' component={News} />
                   

                    <Redirect to="/" />
                </Switch  >
                <Footer />
                <NavMenuRWD />

                <FooterScript />
            </div>         
        </>
        )
    
} export default Home;