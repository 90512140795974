import React, { useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory,Link } from 'react-router-dom';
function Header() {

    const history = useHistory();
    const [intranetLoginAcc, setIntranetLoginAcc] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('admin/login/logininfo')
            .then(response => response.json())
            .then(data => {
                if (!data || !data.roleName) {
                    history.push('/intranet/login');
                } else {
                    setIntranetLoginAcc(data);
                    setLoading(false);
                }
            });
    }, [history]);

    const handlego = (href) => {
        
        history.push(href);
    } 

    const handlelogout = (event) => {
        event.preventDefault();

        fetch('admin/login/logout')
            .then(response => response.json())
            .then(data => { 
                if(data.success)
                {
                    alert("登出成功")
                    history.push('/intranet/login');
                }
            })
            
    } 




    let contents = loading
        ? <p></p>
        : intranetLoginAcc.roleName ;
    return (
        

        <>
            <Helmet>
                <title>臺灣期貨交易所-期貨影音知識網管理平台</title>
            </Helmet>
            <header className="navbar">
                <div className="header-inner">
                    {/*<a onClick={(event) => handlego(event,"/intranet/index")} className="logo"><small>臺灣期貨交易所</small> 期貨影音知識網管理平台</a>*/}
                    <Link to="/intranet/index" className="logo"><small>臺灣期貨交易所</small> 期貨影音知識網管理平台</Link>
                    <div className="navbar-right">
                        <div className="navbar-info">
                            <div className="user">{contents}，您好 !</div>
                            <div className="time">
                                <span id="todayTime"></span>
                            </div>
                            <div className="navbar-nav">
                                <a href="#" onClick={(event) => handlelogout(event)}>登出</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )

}

export default Header;
