import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function FormLogin() {

    const [select, setSelect] = useState([]);

    const [formData, setFormData] = useState("");

    const handleInputChange = (event) => {
        
        setFormData(event.target.value);
    };

    const history = useHistory();

    useEffect(() => {
        function check() { 
        fetch('admin/login/logininfo')
            .then(response => response.json())
            .then(data => {
                if (!data || !data.roleName) {
                    history.push('/intranet/login');
                } else {
                }
            });
        }
        check();
    }, [history]);

    useEffect(() => {
        Data();
    }, [ ]);


    const handleSubmit = (event) => {
        console.log(JSON.stringify(formData))
        event.preventDefault();
        // Do something with the form data
        fetch('/admin/login/setGroup', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    history.push('/intranet/Index');
                } else {
                    // 登入驗證失敗，顯示錯誤訊息
                    alert(data.message);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    async function Data() {

        const response = await fetch('admin/login/groupInfo');
        const data = await response.json();
        setSelect(data);
        setFormData(data[0].groupId);
    }

    return (
        <>

            <div className="login">
                <form onSubmit={handleSubmit}>
                    <section className="login-box">
                        <header className="login-header">
                            <div className="logo"><small>臺灣期貨交易所</small>期貨影音知識網管理平台</div>
                        </header>
                        <ul className="login-form list">
                            <li>
                                <label className="login-item col-3">群組</label>
                                <div className="login-content col-9">
                                    {select.length >0 ? 
                                        <select name="docStatus" value={formData} onChange={handleInputChange}>
                                            {select.map((data,index)=>
                                                <option key={index} value={data.groupId} >{data.groupName}</option>
                                            )}

                                        </select>
                                        :
                                        <span>沒有可選取的權限</span>
                                    }
                                </div>
                            </li>
                        </ul>
                        <div className="btn-wrap">
                            <a onClick={(event) => handleSubmit(event)} className="send" >送出</a>
                            <a href="intranet/login" className="cancel">取消</a>
                        </div>
                    </section>
                </form>
            </div>
            <footer id="footer">
                <div className="inner">
                    建議使用：IE11以上或Chrome、Firefox瀏覽器
                </div>
            </footer>
        </>
    )

}

export default FormLogin;