import React, { Component } from 'react';
import { Helmet } from 'react-helmet'

export class Head extends Component {
    render() {
        return (
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <title>臺灣期貨交易所-期貨影音知識網管理平台</title>
                <meta name="keywords" content="" />
                <link rel="shortcut icon" href="/intranet/assets/favicons/favicon2.ico" />
                <link rel="stylesheet" href="/intranet/assets/css/normalize.css" />
                <link rel="stylesheet" href="/intranet/assets/css/all.css" />
                <link rel="stylesheet" href="/intranet/assets/css/jquery.fancybox.min.css" />
                <link rel="stylesheet" href="/intranet/assets/css/material-icons.css" />
                <link rel="stylesheet" href="/intranet/assets/css/jquery-ui.css" />
                <link rel="stylesheet" href="/intranet/assets/css/basic.css" />
                <link rel="stylesheet" href="/intranet/assets/css/page.css" />
                <link rel="stylesheet" href="/intranet/assets/css/index.css" />
                <link rel="stylesheet" href="/intranet/assets/css/responsive.css" />

            </Helmet>
        )
    }
}
