import React, { Component } from 'react';
import { Helmet } from 'react-helmet'

export class FooterScript extends Component {
    render() {
        return (
            <Helmet>    
                <script src="assets/js/cbp-spmenu.js" ></script>
                <script src="assets/js/function.js" ></script>
            </Helmet>
        )
    }
}

