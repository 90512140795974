import React, { useState, useEffect, useCallback } from 'react';
import YouTube from 'react-youtube';
import printform from "./Print.js"
import $ from "jquery";

function From() {

    const [lean, setLean] = useState([true]);
    const [company, setcompany] = useState([]);
    const [companyen, setcompanyen] = useState([]);

    useEffect(() => {
        $('.page-share > a').click(function () {
            $(this).next('.page-share-list').slideToggle()
        })
    }, []);

    const handleTw = (event) => {
        event.preventDefault();
        setLean(true);
    }
    const handleEn = (event) => {
        event.preventDefault();
        setLean(false);
    }



    //const profiletw = useCallback(async () => {
    //    fetch('web/setting/GetCompanyProfileTw')
    //        .then((response) => response.text())
    //        .then((data) => {
    //            setcompany(data);
    //            console.log('data:' + data);
    //        })
    //        .catch((error) => {
    //            console.log('Error:' + error);
    //        });
    //}, [])

    //const profileen = useCallback(async () => {
    //    fetch('web/setting/GetCompanyProfileEn')
    //        .then((response) => response.text())
    //        .then((data) => {
    //            setcompanyen(data);
    //            console.log('data:' + data);
    //        })
    //        .catch((error) => {
    //            console.log('Error:' + error);
    //        });
    //}, [])

    useEffect(() => {
        profiletw();
        profileen();
    }, []);
    async function profiletw() {
        const response = await fetch('web/setting/GetCompanyProfileTw/');
        const data = await response.text();
        setcompany(data);
    }

    async function profileen() {
        const response = await fetch('web/setting/GetCompanyProfileEn/');
        const data = await response.text();
        setcompanyen(data);
    }

    function VideoPlayer(prop) {

        const videoId = prop.yt;
        const opts = {
            height: '390',
            width: '640',
        };
        return (
            <div className="video" >
                <YouTube videoId={videoId} opts={opts} />
            </div>
        );
    }

    function VideoPlayer2(prop) {

        const videoId = prop.yt;
        const opts = {
            height: '390',
            width: '640',
        };
        return (
            <div className="video" >
                <YouTube videoId={videoId} opts={opts} />
            </div>
        );
    }

    return (
        <>
            <div className="page-main" id="Print_area">
                <div className="page-kv page-kv-course inner">
                    <h2 className="page-kv-title"><strong>公司簡介</strong></h2>
                </div>

                <div className="crumb">
                    <div className="inner crumb-inner">
                        <a href="/index" className="home" title="麵包屑首頁"><i className="fas fa-home"></i>首頁</a> >   
                        <strong className="current">公司簡介</strong>
                    </div>
                </div>

                <section className="page">
                    <div className="inner">
                        <div className="page-header">
                            <div className="page-title">公司簡介</div>
                            <div className="page-share">
                                <a title="分享" />
                                <ul className="page-share-list">
                                    <li>
                                        <a href={"http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location) + "&amp;t=" + encodeURIComponent(document.title) + '&amp;d=' + encodeURIComponent(document.description)} title="fb" target="_blank" rel="noreferrer">
                                            <img src="assets/images/fb_icon.png" alt="fb icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"https://social-plugins.line.me/lineit/share?url=" + encodeURIComponent(window.location) + "&amp;t=" + (new Date()).getTime()} title="line" target="_blank" rel="noreferrer">
                                            <img src="assets/images/line_icon.png" alt="line icon" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"http://twitter.com/home/?status=" + encodeURIComponent(window.location) + '%20' + encodeURIComponent(document.title)} title="twitter" target="_blank" rel="noreferrer">
                                            <img src="assets/images/twitter_icon.png" alt="twitter icon" />
                                        </a>
                                    </li>
                                    <li>
                                        {printform()}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="className-video-cont">
                            {lean  ? 
                                <VideoPlayer yt={company } />
                                :
                                <VideoPlayer2 yt={companyen} />
                            }
                        </div>
                        <div className="page-btn bottom ">
                            <a onClick={handleTw} className="back " title="中文版 ">中文版</a>
                            <a onClick={handleEn} className="back " title="英文版 ">英文版</a>
                        </div>
                    </div>
                </section>
            </div>
            
        </>
    )

} export default From;
