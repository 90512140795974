/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation,Link } from 'react-router-dom';


function FromGroupList() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    const systemid = params.get("systemid");

    const history = useHistory();

    const [grouplist, setgrouplist] = useState([]);

    const [currentPage, setCurrentPage] = useState(0) // 目前頁數
    const [pageSize] = useState(10) // 每頁顯示的資料筆數

    const offset = currentPage * pageSize // 目前頁面要顯示的起始資料位置
    const currentData = grouplist.slice(offset, offset + pageSize) // 目前頁面要顯示的資料

    const [searchData, setsearchData] = useState({
        searchText: '',
        searchStatus: 99,
    });

    useEffect(() => {
        groupData();
    }, []);

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }
    async function groupData() {
        const response = await fetch('admin/group/GetGroupList');
        const data = await response.json();
        if (data.success) {
            setgrouplist(data.data);
        } else {
            setgrouplist(data.data);
            alert("出現錯誤，沒抓到資料");
        }
    }


    function handleStatus(event, status, group) {
        event.preventDefault();
        let stat = "";
        let url = "admin/group/EditGroupStatus/" + status + "/" + group + "/" + systemid;
        if (status === "on") {
            stat = "要將此群組啟用嗎?";

        } else if (status === "off") {
            stat = "要將此群組停用嗎?";
        }
        if (confirm(stat)) {
            // Do something with the form data
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert("修改成功");
                        groupData(page);
                    } else {
                        alert("修改失敗");
                        console.log('Error:' + data.message);
                        groupData(page);
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        }

    }

    function handleDelete(event, group) {
        event.preventDefault();

        let url = "admin/group/DeleteGroup/" + group + "/" + systemid;

        if (confirm("要將此群組刪除嗎?")) {
            // Do something with the form data
            fetch(url)
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        alert("刪除成功");
                        groupData(page);
                    } else {

                        console.log(data.message);
                        groupData(page);
                    }
                })
                .catch((error) => {
                    console.log('Error:' + error);
                });
        }

    }

    function handleSearch(event) {
        event.preventDefault();
        let url = "admin/role/SearchRole";
        // Do something with the form data
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(searchData),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then((data) => {
                setgrouplist(data);
                setCurrentPage(0);
            })
            .catch((error) => {
                console.log('Error:' + error);
            });
    }

    function Pagination(props) {
        const num = Math.ceil(props.count / 10);
        // 計算左右兩邊需要顯示的標籤數量
        let leftRange = Math.max(currentPage - 2, 0);
        let rightRange = Math.min(currentPage + 2, num - 1);

        // 如果左邊不足兩個標籤，則右邊顯示多餘的標籤
        if (leftRange === 0 && rightRange < 4) {
            rightRange = Math.min(rightRange + (4 - rightRange), num - 1);
        }

        // 如果右邊不足兩個標籤，則左邊顯示多餘的標籤
        if (rightRange === num - 1 && leftRange > num - 5) {
            leftRange = Math.max(leftRange - ((num - 1 - rightRange)), 0);
        }

        return (
            <div className="pagination">
                {[...Array(num)].map((e, i) => {
                    if (num < 5 || (i >= leftRange && i <= rightRange)) {
                        return (
                            <a
                                key={i + 1}
                                onClick={() => setCurrentPage(i)}
                                className={currentPage === i ? "active" : ""}
                            >
                                {i + 1}
                            </a>
                        );
                    } else if (i === leftRange - 1 || i === rightRange + 1) {
                        return <span key={i + 1}>...</span>;
                    }
                    return null;
                })}
            </div>
        );
    }



    return (
            <div className="site-content">
                <div className="breadcrumb">
                    <i className="fa-solid fa-house"></i><a onClick={(event) => handlego(event, "/intranet/index")}  href="/intranet/index">首頁</a>
                    <i className="fas fa-angle-right"></i><a >系統管理</a>
                    <i className="fas fa-angle-right"></i><strong className="current">角色及權限管理</strong>
                </div>
                <div className="inner">
                    <div className="unit-title">
                        <h1>角色及權限管理</h1>
                    </div>
                    <div className="widget-box search">
                        <div className="widget-box-header">查詢</div>
                        <div className="widget-box-content">
                            <table>
                                <tbody>
                                    <tr>
                                    <th className="col-2">群組名稱</th>
                                        <td className="col-10">
                                            <input name="date4" type="text" id="" defaultValue="" className="col-12"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>帳號狀態</th>
                                        <td>
                                            <div className="form-inline">
                                                <div><input type="radio" name="1" value=""/><label>全部</label></div>
                                                <div><input type="radio" name="1" value=""/><label>啟用</label></div>
                                                <div><input type="radio" name="1" value=""/><label>停用</label></div>
                                                <div><input type="radio" name="1" value=""/><label>未開通</label></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="btn-wrap search">
                        <a href="#" className="search"><i className="fa-solid fa-magnifying-glass"></i>查詢</a>
                    </div>
                    <div className="info-wrap">
                    <p className="search-total">總計<strong>{grouplist.length}</strong>筆資料</p>
                        <div className="btn">
                            <Link to={"/intranet/admin/system/AddGroup?systemid="+ systemid } >新增</Link>
                        </div>
                    </div>
                    <table className="top-th">
                        <thead>
                            <tr>
                                <th className="num">項次</th>
                                <th>群組名稱</th>
                                <th>狀態</th>
                                <th style={{ width: '600px' }} >操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((group, index) =>
                                <tr key={group.groupId}>
                                    <td className="text-center">{(currentPage * 10) + (index + 1)}</td>
                                    <td>{group.groupName}</td>
                                    <td>{group.groupStatus === 1 ? "啟用" : "停用" }</td>
                                    <td>
                                        <div className="table-btn-wrap">
                                            <a className="table-btn" onClick={(event) => handlego(event,"./EditGroupUser?systemid="+systemid+"&id="+group.groupId)} ><i className="fas fa-users icon"></i>使用者</a>
                                            <a className="table-btn" onClick={(event) => handlego(event,"./EditGroupFunc?systemid=" + systemid + "&id=" + group.groupId)} ><i className="fas fa-cog icon"></i>功能</a>
                                            {group.groupStatus === 1 ?
                                                <a className="table-btn" onClick={(event) => handleStatus(event,"off", group.groupId) }><i className="fas fa-ban icon"></i>停用</a>
                                                :
                                                <a className="table-btn" onClick={(event) => handleStatus(event,"on", group.groupId)}><i className="fas fa-ban icon"></i>啟用</a>
                                            }
                                            <a className="table-btn" onClick={(event) => handlego(event,"./EditGroup?systemid=" + systemid + "&id=" + group.groupId)} ><i className="fas fa-edit icon"></i>編輯</a>
                                            <a className="table-btn" onClick={(event) => handleDelete(event, group.groupId)}><i className="fas fa-trash-alt icon"></i>刪除</a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                <Pagination count={grouplist.length} />
                </div>
            </div>
    )

}
export default FromGroupList;