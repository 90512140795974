/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useHistory,Link } from 'react-router-dom';
import NavMenu from './NavMenu'

function Header() {

    const history = useHistory();

    const handlego = (event, href) => {
        event.preventDefault();
        history.push(href);
    }

    const [searchData, setsearchData] = useState([]);

    const handleInputChange = (event) => {
        setsearchData(event.target.value)
    };


        return (
            <header className="header">
                <div className="header-bg">
                    <div className="header-inner">
                        <a id="showLeftPush" className="hamburger" title="開啟rwd選單"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></a>
                        <a href="/index" className="logo" title="回到首頁">
                            <h1 className="hide">期貨影音知識網</h1>
                            <img src="assets/images/logo.png" alt="期貨交易所期貨影音知識網logo"/>
                        </a>
                        <div className="header-misc">
                            <div className="header-misc-inner">
                                <ul className="header-misc-nav ulfix">
                                    <li><a href="/index" className="misc-i1" title="回首頁"></a></li>
                                    <li><a onClick={(event) => handlego(event,"/Sitmap")}  className="misc-i2" title="網站導覽"></a></li>
                                    <li><a  className="misc-i3 header-search-trigger" title="搜尋"></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <NavMenu />

                <div className="header-search-box hide">
                    <div className="inner">
                        <input type="text" name="searchText" value={searchData} onChange={handleInputChange} />
                        <a className="btn searchclose" onClick={() => searchAll()} >查詢</a>
                        {/*<a className="btn adv-btn searchclose" onClick={() => google_search()}>進階查詢</a>*/}
                        <a className="header-search-close"><img src="assets/images/i-close.svg" width="20" height="20" /></a>
                    </div>
                </div>
            </header>
    )

    function searchAll() {
        history.push('/SearchCourseList?search=' + searchData + '&page=1');
    }
    

    function google_search() {
        var kw = encodeURIComponent(searchData);
        if (kw.indexOf('.') > -1) {
            kw = kw.replace(".", "%2e");
        }
        if (kw.indexOf('/') > -1) {
            kw = kw.replace("/", "／");
        }
        history.push("/SearchAllList?q=" + kw);
    }

}
export default Header;