import React, { Component } from 'react';
import { Helmet } from 'react-helmet'

export class Head extends Component {
    render() {
        return (
            <Helmet>
                <meta charset="utf-8"/>
                <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
                <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no"/>
                <title>期貨交易所-期貨影音知識網</title>
                <link rel="shortcut icon" href="assets/favicons/favicon.ico" />
                <link rel="stylesheet" href="assets/css/normalize.css" />
                <link rel="stylesheet" href="assets/css/all.css" />
                <link rel="stylesheet" href="assets/css/bootstrap.css" />
                <link rel="stylesheet" href="assets/css/slick.css" />
                <link rel="stylesheet" href="assets/css/cbp-spmenu.css" />
                <link rel="stylesheet" href="assets/css/jquery-ui.min.css" />
                <link rel="stylesheet" href="assets/css/basic.css" />
                <link rel="stylesheet" href="assets/css/index.css" />
                <link rel="stylesheet" href="assets/css/page.css" />
                <link rel="stylesheet" href="assets/css/responsive.css" />
                
            </Helmet>
        )
    }
}